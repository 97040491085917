<template>
    <div class="sms-dialog">
        <div v-if="!initLoading" class="dialog-items-wrapper" v-bar>
            <div class="scroll-wrapper" ref="scrollWrapper">
                <base-spinner v-if="canLoadMoreTop"></base-spinner>
                <div class="sentinel" ref="topSentinel"></div>
                <div class="list-wrapper">
                    <div
                        v-for="(item, index) in dialogItems"
                        :key="index"
                        class="dialog-item"
                    >
                        <div
                            v-if="item.type === DIALOG_ITEM_UNREAD_MESSAGES_SEPARATOR"
                            ref="unreadMessagesSeparator"
                            class="unread-messages-separator"
                        ></div>
                        <chip
                            v-if="item.type === DIALOG_ITEM_DATE_LABEL"
                            :text="item.date"
                            color="border-gray"
                            class="item-date-label"
                        ></chip>

                        <div
                            v-if="item.type === DIALOG_ITEM_RECEIVED_ON_LABEL"
                            class="item-received-on"
                        >
                            <span class="received-on">{{ $t("sms-dialog.received-on") }}: </span>
                            <span v-if="item.name" class="name">'{{ item.name }}' </span>
                            <span class="number">{{ item.number }}</span>
                        </div>

                        <sms-dialog-message
                            v-if="item.type === DIALOG_ITEM_MESSAGE"
                            @resend="resendSms"
                            :message="item.message"
                            :sending-statuses="statusesOfSentSMSes[item.message.id]"
                            :avatar-settings="item.message.type === SMS_TYPE_OUTBOUND ? userAvatarSettings : contactAvatarSettings"
                            :sender-name="item.message.type === SMS_TYPE_OUTBOUND ? user.name : (contact.name ? contact.name : formatNumber(chat.number))"
                            :window-width="windowWidth"
                            :ref="!item.message.is_read ? 'unreadMessage' : null"
                            :data-id="item.message.id"
                            class="item-message"
                        ></sms-dialog-message>
                    </div>
                </div>
                <div class="sentinel" ref="bottomSentinel"></div>
                <base-spinner v-if="canLoadMoreBottom"></base-spinner>
            </div>
        </div>

        <base-spinner v-else center></base-spinner>

        <div class="input-area">
            <base-textarea
                v-model="messageText"
                :max-height="noSmsNumbers ? 40 : 200"
                :disabled="!isPhoneNumber"
                @keydown.native.ctrl.enter.prevent="textareaAddNewLine"
                @keydown.native.enter.exact.prevent="sendNewSms"
                placeholder="message"
                autofocus
                ref="textarea"
            ></base-textarea>

            <div class="controls">
                <caller-id-select-dropdown
                    @select="onSelectFromNumber($event.id)"
                    :selected-item="fromNumber"
                    :options="fromNumberOptions"
                    :search-placeholder="$t('caller-id-select.search-placeholder-sms')"
                >
                    <base-button
                        :text="fromNumberText"
                        :disabled="!isPhoneNumber"
                        icon="icon-sim-card"
                        size="big"
                        ref="selectFromNumberActivator"
                        disable-borders
                        text-over-icon
                    ></base-button>
                </caller-id-select-dropdown>

                <base-button
                    @click="sendNewSms"
                    :disabled="!isPhoneNumber || isReachedMaxSmsCount"
                    icon="icon-send"
                    size="big"
                    disable-borders
                ></base-button>

                <sms-characters-counter
                    @update:is-reached-max-sms-count="isReachedMaxSmsCount = $event"
                    :text="messageText"
                ></sms-characters-counter>
            </div>

            <div v-if="noSmsNumbers" class="no-sms-numbers">{{ $t("panel.sms.no-sms-numbers") }}</div>
        </div>
    </div>
</template>

<script>
import SmsDialogMessage from "./SmsDialogMessage.vue";
import {mapActions, mapGetters} from "vuex";
import BaseButton from "../../defaults/BaseButton.vue";
import BaseTextarea from "../../defaults/BaseTextarea.vue";
import BaseSelect from "../../defaults/BaseSelect.vue";
import CallerIdSelectSearchInput from "../../softphone/callerIdSelect/CallerIdSelectSearchInput.vue";
import {
    formatNumber,
    getPhoneNumberDigitsOnly,
    numbersAreMatched,
    isPhoneNumber,
    sanitizePhoneNumber
} from "../../../utils/phoneNumbers";
import {SMS_TYPE_OUTBOUND, SMS_TYPE_INBOUND} from "../../../store/modules/SMSes";
import BaseSpinner from "../../defaults/BaseSpinner.vue";
import Chip from "../../defaults/Chip.vue";
import SocketIOHelper from "../../../helpers/SocketIOHelper";
import dayjs from "dayjs"
import SmsCharactersCounter from "./SmsCharactersCounter.vue";
import CallerIdSelectDropdown from "../../softphone/callerIdSelect/CallerIdSelectDropdown.vue";

const DIALOG_ITEM_DATE_LABEL = "date_label"
const DIALOG_ITEM_RECEIVED_ON_LABEL = "received_on_label"
const DIALOG_ITEM_MESSAGE = "message"
const DIALOG_ITEM_UNREAD_MESSAGES_SEPARATOR = "unread_messages_separator"

const MESSAGES_FETCH_LIMIT = 100
const MAX_NUMBER_OF_MESSAGES_DISPLAYED = 300

export default {
    name: "SmsDialog",
    components: {
        CallerIdSelectDropdown,
        SmsCharactersCounter,
        Chip, BaseSpinner, CallerIdSelectSearchInput, BaseSelect, BaseTextarea, BaseButton, SmsDialogMessage},
    props: {
        chat: {
            type: Object,
            default: {}
        },
        contact: {
            type: Object,
            default: {}
        },
        windowWidth: Number
    },
    data() {
        return {
            messageText: "",
            searchModel: "",
            initLoading: false,
            moreLoading: false,
            idsOfReadSMSes: [],
            readSMSesTimeout: null,
            unreadMessageObserver: null,
            statusesOfSentSMSes: {},
            topListObserver: null,
            bottomListObserver: null,
            canLoadMoreBottom: false,
            canLoadMoreTop: false,
            prevScrollHeightMinusScrollTop: 0,
            prevScrollTop: 0,
            isSmsSent: false,
            isReachedMaxSmsCount: false,
            SMS_TYPE_OUTBOUND,
            DIALOG_ITEM_DATE_LABEL,
            DIALOG_ITEM_RECEIVED_ON_LABEL,
            DIALOG_ITEM_MESSAGE,
            DIALOG_ITEM_UNREAD_MESSAGES_SEPARATOR
        }
    },
    created() {
        this.loadInitChatHistory()

        this.setUnreadMessageObserver()
        this.setTopListObserver()
        this.setBottomListObserver()

        SocketIOHelper.socket.on("sms_received", this.onSmsReceived)

        SocketIOHelper.addEventListener("reconnect", this.loadInitChatHistory)
    },
    destroyed() {
        this.disconnectAllObservers()

        SocketIOHelper.removeEventListener("reconnect", this.loadInitChatHistory)
        SocketIOHelper.socket.off("sms_received", this.onSmsReceived)
    },
    watch: {
        chat() {
            this.readSMSes().catch(() => {})

            this.disconnectAllObservers()

            this.loadInitChatHistory()

            if (this.$refs.textarea) {
                this.$refs.textarea.focus()
            }
        }
    },
    computed: {
        ...mapGetters("user", ["user", "smsNumbers", "languageCode", "defaultSmsFromNumberId"]),
        ...mapGetters("SMSes", ["messages"]),
        ...mapGetters("details", ["getContactByNumber"]),

        userAvatarSettings() {
            return {
                avatar: this.user.avatar,
                name: this.user.name,
                color: this.user.color
            }
        },

        contactAvatarSettings() {
            return {
                avatar: this.contact.avatar,
                name: this.contact.name,
                color: this.contact.color
            }
        },

        noSmsNumbers() {
            return this.smsNumbers.length === 0
        },

        fromNumber() {
            return this.fromNumberOptions.find((number) => number.id === this.chat.from_number_id)
        },

        fromNumberOptions() {
            return this.smsNumbers.map((number) => {
                const contact = this.getContactByNumber(number.num)
                const name = contact.id ? contact.name : number.name
                return {name: name, number: formatNumber(number.num), id: number.id}
            })
        },

        fromNumberText() {
            if (!this.fromNumber) {
                return "?"
            }

            if (this.fromNumber.name) {
                return this.fromNumber.name.slice(0, 1)
            }

            return sanitizePhoneNumber(this.fromNumber.number).slice(-3)
        },

        dialogItems() {
            let result = []
            let unreadMessageFound = false
            let lastReceivedOnNumber = ""

            for (let i = 0; i < this.messages.length; i++) {
                let prevMessage = this.messages[i - 1]
                let message = this.messages[i]

                if (!message.is_read && !unreadMessageFound) {
                    unreadMessageFound = true
                    result.push({
                        type: DIALOG_ITEM_UNREAD_MESSAGES_SEPARATOR
                    })
                }

                if (i === 0 || !dayjs(prevMessage.date).isSame(message.date, 'day')) {
                    result.push({
                        type: DIALOG_ITEM_DATE_LABEL,
                        date: dayjs().isSame(message.date, 'year') ? dayjs(message.date).locale(this.languageCode).format("MMMM D") : dayjs(message.date).locale(this.languageCode).format("LL")
                    })
                }

                if (message.type === SMS_TYPE_INBOUND && lastReceivedOnNumber !== message.received_on) {
                    const number = this.smsNumbers.find(number => numbersAreMatched(number.num, message.received_on))
                    result.push({
                        type: DIALOG_ITEM_RECEIVED_ON_LABEL,
                        number: formatNumber(message.received_on),
                        name: number ? number.name : ''
                    })
                    lastReceivedOnNumber = message.received_on
                }

                result.push({
                    type: DIALOG_ITEM_MESSAGE,
                    message
                })
            }

            return result
        },

        isPhoneNumber() {
            return isPhoneNumber(this.chat.number)
        }
    },
    methods: {
        ...mapActions("SMSes", [
            "getInitChatHistory",
            "getChatHistoryBefore",
            "getChatHistoryAfter",
            "updateChatFromNumber",
            "createNewSms",
            "markSMSesAsRead",
            "updateChatNumberOfUnreadMessages",
            "smsSend",
            "updateChatLastSms",
            "updateMessageDate",
            "trimMessagesFromTheBeginning",
            "trimMessagesFromTheEnd"
        ]),

        loadInitChatHistory() {
            this.initLoading = true
            this.getInitChatHistory({chatId: this.chat.id, limit: MESSAGES_FETCH_LIMIT})
                .then((messages) => {
                    this.initLoading = false

                    this.updateFromNumber()

                    this.$nextTick(() => {
                        if (this.messages.length < MESSAGES_FETCH_LIMIT) {
                            this.canLoadMoreTop = false
                            this.canLoadMoreBottom = false
                        } else {
                            this.canLoadMoreTop = true
                            this.canLoadMoreBottom = true
                        }

                        const unreadMessage = this.messages.find((message) => !message.is_read)
                        if (unreadMessage) {
                            this.scrollToFirstUnreadMessage()
                            this.$nextTick(() => {
                                this.observeUnreadMessages()
                                this.observeTopSentinel()
                                this.observeBottomSentinel()
                            })
                        } else {
                            this.canLoadMoreBottom = false

                            this.scrollToBottom()
                            this.$nextTick(() => {
                                this.observeTopSentinel()
                                this.observeBottomSentinel()
                            })
                        }
                    })
                })
                .catch(() => this.initLoading = false)
        },

        onSelectFromNumber(fromNumberId) {
            if (fromNumberId !== this.chat.from_number_id) {
                this.updateChatFromNumber({chatId: this.chat.id, fromNumberId}).catch(() => {
                })
            }
        },

        scrollToFirstUnreadMessage() {
            if (!this.$refs.unreadMessagesSeparator) {
                this.scrollToBottom()
                return
            }

            const separator = this.$refs.unreadMessagesSeparator[0]
            const wrapper = this.$refs.scrollWrapper

            if (separator && wrapper) {
                wrapper.scrollTop = separator.offsetTop - 16
            }
        },

        scrollToBottom() {
            const wrapper = this.$refs.scrollWrapper
            if (wrapper) {
                wrapper.scrollTop = wrapper.scrollHeight
            }
        },

        setUnreadMessageObserver() {
            this.unreadMessageObserver = new IntersectionObserver(entries => {
                entries.forEach(({ target, isIntersecting}) => {
                    if (!isIntersecting) {
                        return
                    }

                    this.unreadMessageObserver.unobserve(target)

                    const smsId = Number(target.getAttribute("data-id"))
                    if (this.idsOfReadSMSes.includes(smsId)) {
                        return
                    }

                    this.idsOfReadSMSes.push(smsId)

                    this.updateChatNumberOfUnreadMessages({
                        chatId: this.chat.id,
                        numberOfUnreadMessages: Math.max(this.chat.number_of_unread_messages - 1, 0)
                    })

                    clearTimeout(this.readSMSesTimeout)
                    this.readSMSesTimeout = setTimeout(() => {
                        this.recordScrollTopPosition()
                        this.readSMSes().then(() => this.$nextTick(this.restorePrevScrollTopPosition)).catch(() => {})
                    }, 5000)
                })
            }, {threshold: 0.5})
        },

        observeUnreadMessages() {
            if (!this.unreadMessageObserver) {
                return
            }

            this.unreadMessageObserver.disconnect()
            this.$refs.unreadMessage.forEach(unreadMessage => {
                const element = unreadMessage?.$el
                if (element) {
                    this.unreadMessageObserver.observe(element)
                }
            })
        },

        recordScrollHeightMinusScrollTopPosition() {
            const element = this.$refs.scrollWrapper

            if (element) {
                this.prevScrollHeightMinusScrollTop = element.scrollHeight - element.scrollTop
            }
        },

        restorePrevScrollHeightMinusScrollTopPosition() {
            const element = this.$refs.scrollWrapper

            if (element) {
                element.scrollTop = element.scrollHeight - this.prevScrollHeightMinusScrollTop
            }
        },

        recordScrollTopPosition() {
            const element = this.$refs.scrollWrapper

            if (element) {
                this.prevScrollTop = element.scrollTop
            }
        },

        restorePrevScrollTopPosition() {
            const element = this.$refs.scrollWrapper

            if (element) {
                element.scrollTop = this.prevScrollTop
            }
        },

        setTopListObserver() {
            this.topListObserver = new IntersectionObserver(entries => {
                entries.forEach(({ target, isIntersecting}) => {
                    if (!isIntersecting || this.moreLoading || !this.canLoadMoreTop) {
                        return
                    }

                    this.moreLoading = true
                    this.recordScrollHeightMinusScrollTopPosition()
                    this.readSMSes().then(() => {
                        return this.getChatHistoryBefore({
                            chatId: this.chat.id, smsId: this.messages[0].id, limit: MESSAGES_FETCH_LIMIT
                        })
                    }).then((messages) => {
                        this.moreLoading = false
                        if (messages.length === 0) {
                            this.canLoadMoreTop = false
                        }

                        this.$nextTick(() => {
                            this.restorePrevScrollHeightMinusScrollTopPosition()
                            // if the number of messages exceeds the maximum number of messages displayed, then some messages must be deleted
                            if (this.messages.length > MAX_NUMBER_OF_MESSAGES_DISPLAYED) {
                                this.recordScrollTopPosition()
                                this.trimMessagesFromTheEnd(MESSAGES_FETCH_LIMIT)
                                this.canLoadMoreBottom = true
                                this.$nextTick(this.restorePrevScrollTopPosition)
                            }

                            this.$nextTick(() => {
                                if (this.chat.number_of_unread_messages > 0) {
                                    this.observeUnreadMessages()
                                }
                            })
                        })
                    }).catch(() => {
                        this.moreLoading = false
                    })
                })
            })
        },

        observeTopSentinel() {
            if (!this.topListObserver) {
                return
            }

            this.topListObserver.disconnect()
            this.topListObserver.observe(this.$refs.topSentinel)
        },

        setBottomListObserver() {
            this.bottomListObserver = new IntersectionObserver(entries => {
                entries.forEach(({ target, isIntersecting}) => {
                    if (!isIntersecting || this.moreLoading || !this.canLoadMoreBottom) {
                        return
                    }

                    this.moreLoading = true

                    this.readSMSes().then(() => {
                        return this.getChatHistoryAfter({
                            chatId: this.chat.id, smsId: this.messages[this.messages.length - 1].id, limit: MESSAGES_FETCH_LIMIT
                        })
                    }).then((messages) => {
                        this.moreLoading = false
                        if (messages.length === 0) {
                            this.canLoadMoreBottom = false
                        }

                        this.$nextTick(() => {
                            // if the number of messages exceeds the maximum number of messages displayed, then some messages must be deleted
                            if (this.messages.length > MAX_NUMBER_OF_MESSAGES_DISPLAYED) {
                                this.recordScrollHeightMinusScrollTopPosition()
                                this.trimMessagesFromTheBeginning(MESSAGES_FETCH_LIMIT)
                                this.canLoadMoreTop = true
                                this.$nextTick(this.restorePrevScrollHeightMinusScrollTopPosition)
                            }

                            this.$nextTick(() => {
                                if (this.chat.number_of_unread_messages > 0) {
                                    this.observeUnreadMessages()
                                }
                            })
                        })
                    }).catch(() => {
                        this.moreLoading = false
                    })
                })
            })
        },

        observeBottomSentinel() {
            if (!this.bottomListObserver) {
                return
            }

            this.bottomListObserver.disconnect()
            this.bottomListObserver.observe(this.$refs.bottomSentinel)
        },

        readSMSes() {
            if (this.idsOfReadSMSes.length === 0) {
                return Promise.resolve()
            }

            clearTimeout(this.readSMSesTimeout)
            return this.markSMSesAsRead(this.idsOfReadSMSes).then(() => {
                this.idsOfReadSMSes = []
            })
        },

        async sendNewSms() {
            const text = this.messageText.trim()

            if (text.length === 0 || this.smsNumbers.length === 0 || this.isSmsSent || this.isReachedMaxSmsCount) {
                return
            }

            if (!this.chat.from_number_id) {
                setTimeout(() => {
                    this.$refs.selectFromNumberActivator.click()
                }, 10)
                return
            }

            this.isSmsSent = true

            const sentAt = this.getFormattedNowDate()
            const id = Date.now()
            this.createNewSms({
                id,
                text,
                date: sentAt,
                type: SMS_TYPE_OUTBOUND,
                receivedOn: "",
                isRead: true
            })

            this.updateChatLastSms({
                chatId: this.chat.id,
                date: sentAt,
                text,
                type: SMS_TYPE_OUTBOUND
            })

            this.$nextTick(() => {
                this.scrollToBottom()
            })

            this.makeSmsSendRequest(id, text, sentAt).finally(() => this.isSmsSent = false)
            this.messageText = ""
        },

        resendSms(id) {
            const message = this.messages.find(message => message.id === id)
            if (!message) {
                return
            }

            const sentAt = this.getFormattedNowDate()
            this.updateMessageDate({id, date: sentAt})

            this.makeSmsSendRequest(id, message.text, sentAt)
        },

        makeSmsSendRequest(id, text, sentAt) {
            this.$set(this.statusesOfSentSMSes, id, {processing: true, failed: false})
            return this.smsSend({
                toNumber: getPhoneNumberDigitsOnly(this.chat.number),
                text: text,
                sentAt
            }).then(() => {
                this.$set(this.statusesOfSentSMSes, id, {processing: false, failed: false})
                this.$nextTick(() => {
                    delete this.statusesOfSentSMSes[id]
                })
            }).catch(() => {
                this.$set(this.statusesOfSentSMSes, id, {processing: false, failed: true})
            })
        },

        getFormattedNowDate() {
            return dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
        },

        textareaAddNewLine(event) {
            let caret = event.target.selectionStart
            event.target.setRangeText("\n", caret, caret, "end")
            this.messageText = event.target.value
        },

        disconnectAllObservers() {
            if (this.unreadMessageObserver) {
                this.unreadMessageObserver.disconnect()
            }

            if (this.topListObserver) {
                this.topListObserver.disconnect()
            }

            if (this.bottomListObserver) {
                this.bottomListObserver.disconnect()
            }
        },

        isScrolledToBottom() {
            const wrapper = this.$refs.scrollWrapper
            if (wrapper) {
                const imprecision = 5
                return wrapper.scrollTop >= (wrapper.scrollHeight - wrapper.offsetHeight) - imprecision
            }

            return false
        },

        onSmsReceived(socketData) {
            if (!this.chat.id || socketData.chat.id !== this.chat.id) {
                return
            }

            const isScrolledToBottom = this.isScrolledToBottom()

            this.createNewSms({
                id: socketData.sms.id,
                text: socketData.sms.text,
                date: socketData.sms.date,
                type: SMS_TYPE_INBOUND,
                receivedOn: socketData.sms.received_on,
                isRead: false
            })

            this.updateFromNumber()

            // if the user is at the bottom of the dialog, then when receiving a new SMS, need to scroll down (to display the SMS) and mark the SMS as read
            // if the user is somewhere at the top of the dialog, then need to increase the number of unread messages
            this.$nextTick(() => {
                this.observeUnreadMessages()
                if (isScrolledToBottom) {
                    this.scrollToBottom()
                } else {
                    this.updateChatNumberOfUnreadMessages({
                        chatId: this.chat.id,
                        numberOfUnreadMessages: this.chat.number_of_unread_messages + 1
                    })
                }
            })
        },

        /**
         * when opening a chat and during correspondence,
         * change from number to the number to which the SMS was last sent,
         * if such a number is not found, then change the number to the default one
         */
        updateFromNumber() {
            let lastReceivedOnNumber = ""

            for (let i = this.messages.length - 1; i >= 0; i--) {
                let sms = this.messages[i]
                if (sms.type === SMS_TYPE_INBOUND) {
                    lastReceivedOnNumber = sms.received_on
                    break
                }
            }

            if (lastReceivedOnNumber) { // if the last incoming message is found
                const currentSmsNumber = this.smsNumbers.find((number) => numbersAreMatched(number.num, lastReceivedOnNumber))
                if (currentSmsNumber) {
                    this.onSelectFromNumber(currentSmsNumber.id)
                    return
                }

                this.setDefaultSmsFromNumberId()
            } else if (
                !this.chat.from_number_id
            ) { // if the last incoming message is not found and the chat not have a "from_number_id"
                this.setDefaultSmsFromNumberId()
            }
        },

        /**
         * Function sets "from_number_id" to the value from "defaultSmsFromNumberId" if specified
         * otherwise sets first number from "smsNumbers" list
         */
        setDefaultSmsFromNumberId() {
            if (this.defaultSmsFromNumberId) {
                this.onSelectFromNumber(this.defaultSmsFromNumberId)
            } else if (this.smsNumbers && this.smsNumbers.length) {
                this.onSelectFromNumber(this.smsNumbers[0].id)
            }
        },

        formatNumber
    }
}
</script>

<style scoped>

</style>