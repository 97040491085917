<template>
    <div class="auth-errors">
        <p v-for="error in errors">{{ $te(error) ? $t(error) : error }}</p>
    </div>
</template>

<script>
export default {
    name: "AuthErrors",
    props: {
        errors: {
            type: Array,
            default() {
                return []
            }
        }
    }
}
</script>

<style scoped>

</style>