export const TABLE_HEADER_SORT =  Object.freeze({
    ASC: "ASC",
    DESC: "DESC",
    DEFAULT: ""
})
export const TABLE_HEADER_ALIGN = Object.freeze({
    LEFT: "left",
    CENTER: "center",
    RIGHT: "right"
})

export default class TableHeader {
    /**
     * @param {string} title
     * @param {string} key
     * @param {TABLE_HEADER_ALIGN} align
     * @param {string|number} fr
     * @param {boolean} allowSort
     * @param {TABLE_HEADER_SORT} sort
     * @param {string} className
     */
    constructor({title, key, align, fr, allowSort, sort, className}) {
        this._title = title
        this._key = key
        this._align = align ? align : TABLE_HEADER_ALIGN.LEFT
        this._fr = fr ? fr : 1
        this._allowSort = Boolean(allowSort)
        this._sort = sort ? sort : TABLE_HEADER_SORT.DEFAULT
        this._align = align;
        this._allowSort = allowSort;
        this._className = className;
    }

    get fr() {
        return this._fr;
    }

    set fr(value) {
        this._fr = value;
    }

    get sort() {
        return this._sort;
    }

    set sort(value) {
        this._sort = value;
    }

    get title() {
        return this._title;
    }

    set title(value) {
        this._title = value;
    }

    get key() {
        return this._key;
    }

    set key(value) {
        this._key = value;
    }

    get align() {
        return this._align;
    }

    set align(value) {
        this._align = value;
    }

    get allowSort() {
        return this._allowSort;
    }

    set allowSort(value) {
        this._allowSort = value;
    }

    get className() {
        return this._className;
    }

    set className(value) {
        this._className = value;
    }
}