<template>
    <div class="auth-input">
        <span class="label">{{ $te(label) ? $t(label) : label }}</span>

        <base-input
            :value="value"
            @input="onInput"
            @blur="onBlur"
            :error="error"
            :type="type"
            :focused="focused"
            :autocomplete="autocomplete"
            color="login"
            rounded
        ></base-input>
    </div>
</template>

<script>
import BaseInput from "../../defaults/BaseInput.vue";

export default {
    name: "AuthInput",
    components: {BaseInput},
    props: {
        value: {
            type: String
        },
        label: {
            type: String
        },
        type: {
            type: String,
            default: "text"
        },
        error: {
            type: Boolean,
            default: false
        },
        focused: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onInput(value) {
            this.$emit("input", value)
        },
        onBlur() {
            this.$emit("blue")
        }
    }
}
</script>

<style scoped>

</style>