<template>
    <div class="dnd-switcher-status" :class="classes">
        <base-spinner
            v-if="loading"
            :color="spinnerColor"
            size="small"
        ></base-spinner>

        <template v-else-if="isOnline">
            <span v-if="isHover" class="icon icon-pause"></span>

            <span v-else class="icon icon-done"></span>
        </template>

        <template v-else>
            <span v-if="isHover" class="icon icon-play"></span>

            <span v-else class="icon icon-rectangle"></span>
        </template>
    </div>
</template>

<script>
import {STATUS_SIZE, STATUS_COLOR} from "./config";
import BaseSpinner from "../../defaults/BaseSpinner.vue";

export default {
    name: "DNDSwitcherStatus",
    components: {BaseSpinner},
    props: {
        isOnline: {
            type: Boolean,
            default: true
        },
        color: {
            type: String,
        },
        size: {
            type: String,
            default: STATUS_SIZE.BG
        },
        loading: {
            type: Boolean,
            default: false
        },
        isHover: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            return {
                "dnd-switcher-status-color-red": this.color === STATUS_COLOR.RED,
                "dnd-switcher-status-color-inverted-red": this.color === STATUS_COLOR.INVERTED_RED,
                "dnd-switcher-status-color-green": this.color === STATUS_COLOR.GREEN,

                "dnd-switcher-status-size-bg": this.size === STATUS_SIZE.BG,
                "dnd-switcher-status-size-sm": this.size === STATUS_SIZE.SM,

                "dnd-switcher-status-disabled": this.disabled
            }
        },

        spinnerColor() {
            if (this.color === STATUS_COLOR.INVERTED_RED) {
                return "red"
            }

            return "white"
        }
    }
}
</script>

<style scoped>

</style>