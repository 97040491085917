<template>
    <div
        class="call-history-call-type-cell"
        :class="[item.type, (item.isAnonymous() ? 'anonymous-call' : '')]"
        @click.stop="speedDial(item.number, item.callerid)"
    >
        <span class="entry-type-icon icon-call"></span>
        <span class="entry-type-icon" :class="iconClass"></span>
        <span class="entry-type-text">{{ $t(item.typeText) }}</span>
    </div>
</template>

<script>
import {speedDial} from "../../../../utils/softphone";
import CallHistory from "../../../../models/CallHistory";

export default {
    name: "CallHistoryCallTypeCell",
    props: {
        item: {
            type: CallHistory
        }
    },
    computed: {
        iconClass() {
            if (this.item.isOutgoing()) {
                return 'icon-call-outgoing'
            }

            if (this.item.isIncoming()) {
                return 'icon-call-ingoing'
            }

            if (this.item.isMissed()) {
                return 'icon-call-missed'
            }

            return ''
        }
    },
    methods: {
        speedDial
    }
}
</script>

<style scoped>

</style>