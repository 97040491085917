<template>
    <div class="download-app-activator">
        <span class="icon icon-file_download"></span>
        <span class="name">{{ $t("login.apps.title") }}</span>

        <span v-if="active" class="icon icon-expand-up"></span>
        <span v-else class="icon icon-expand-down"></span>
    </div>
</template>

<script>
export default {
    name: "DownloadAppActivator",
    props: {
        active: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>

</style>