<template>
    <span class="icon icon-copy copy-button" @click.stop="onClick"></span>
</template>

<script>
export default {
    name: "CopyButton",
    methods: {
        onClick() {
            this.$emit('click')
        }
    }
}
</script>

<style scoped>

</style>