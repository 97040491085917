<template>
    <span class="electron-app-version">
        {{ $t("electron-app-version") }} {{ electronAppVersion }}
    </span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "ElectronAppVersion",
    computed: {
        ...mapGetters(["electronAppVersion"])
    }
}
</script>

<style scoped>

</style>