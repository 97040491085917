<template>
    <tr
        @click="onRowClick"
        @mouseover="isHover = true"
        @mouseleave="isHover = false"
        class="call-history-table-row"
    >
        <td class="call-history-table-row-cell">
            <contact
                :contact="contact"
                :call-type="item.type"
            >
                <template v-slot:info>
                    <contact-info
                        :is-hover="isHover"
                        :primary-info="contact.id ? contact.name : unknownItemName"
                        :secondary-info="contact.id ? formattedNumber : ''"
                    ></contact-info>
                </template>
            </contact>
        </td>

        <td class="call-history-table-row-cell">
            <call-history-call-type-cell
                :item="item"
            ></call-history-call-type-cell>
        </td>

        <td v-if="!breakpoints.isTabletOrMobile" class="call-history-table-row-cell">
            <call-history-callerid-cell
                :is-hover="isHover"
                :item="item"
            ></call-history-callerid-cell>
        </td>

        <td v-if="!breakpoints.isTabletOrMobile" class="call-history-table-row-cell">
            {{ callDuration }}
        </td>

        <td class="call-history-table-row-cell">
            <call-history-date-cell
                :is-expanded="expandedTab === EXPANDED_TABS.MOBILE"
                :item="item"
            ></call-history-date-cell>
        </td>

        <!-- Actions -->
        <td v-if="!breakpoints.isTabletOrMobile" class="call-history-table-row-cell">
            <div class="call-history-table-row-actions">
                <base-button
                    v-if="!contact.id"
                    @click="showAddContactPopup(item.number)"
                    size="medium"
                    color="table"
                    icon="icon-person-add"
                    class="add-contact-btn"
                ></base-button>

                <base-button
                    v-if="canShowIntegrations && !item.isAnonymous()"
                    @click="expand(EXPANDED_TABS.INTEGRATIONS)"
                    size="medium"
                    :color="expandedTab === EXPANDED_TABS.INTEGRATIONS ? 'dark-gray' : 'table'"
                    icon="icon-join-right-black"
                    class="show-crms-btn"
                ></base-button>

                <base-button
                    v-if="item.isCallRecordingsExist"
                    @click="expand(EXPANDED_TABS.CALL_RECORDINGS)"
                    size="medium"
                    :color="expandedTab === EXPANDED_TABS.CALL_RECORDINGS ? 'dark-gray' : 'table'"
                    icon="icon-mic"
                    class="show-records-btn"
                ></base-button>
            </div>
        </td>
    </tr>
</template>

<script>
import Avatar from "../../defaults/Avatar.vue";
import BaseButton from "../../defaults/BaseButton.vue";
import {formatNumber} from "../../../utils/phoneNumbers";
import {speedDial} from "../../../utils/softphone";
import CopyButton from "../../defaults/CopyButton.vue";
import BaseTooltip from "../../defaults/BaseTooltip.vue";
import IntegrationsInline from "../../integrations/IntegrationsInline.vue";
import CallRecordingsViewer from "./expandedTabs/CallRecordingsExpandedTab.vue";
import {showAddContactPopup, showEditContactPopup} from "../../../utils/phonebooks";
import {copyNumber, showTooltip, copyNameWithNumber} from "../../../utils/copy";
import {mapGetters} from "vuex";
import breakpoints from "../../../plugins/breakpoints";
import Contact from "../../defaults/contact/Contact.vue";
import CallHistoryCallTypeCell from "./cells/CallHistoryCallTypeCell.vue";
import CallHistoryCalleridCell from "./cells/CallHistoryCalleridCell.vue";
import CallHistoryDateCell from "./cells/CallHistoryDateCell.vue";
import callHistoryTableRowMixin from "./callHistoryTableRowMixin";
import {EXPANDED_TABS} from "./config";
import ContactInfo from "../../defaults/contact/ContactInfo.vue";

export default {
    name: "CallHistoryTableRow",
    components: {
        ContactInfo,
        CallHistoryDateCell,
        CallHistoryCalleridCell,
        CallHistoryCallTypeCell,
        Contact,
        CallRecordingsViewer, BaseButton, IntegrationsInline, Avatar, CopyButton, BaseTooltip
    },
    mixins: [callHistoryTableRowMixin],
    props: {
        expandedTab: {
            type: String,
            default: "",
            validator(value) {
                return [EXPANDED_TABS.CALL_RECORDINGS, EXPANDED_TABS.INTEGRATIONS, EXPANDED_TABS.MOBILE, ""].includes(value)
            }
        }
    },
    data() {
        return {
            isHover: false,
            breakpoints,
            EXPANDED_TABS
        }
    },
    computed: {
        ...mapGetters('details', ['callTypes', 'getContactByNumber']),
        ...mapGetters('user', ['languageCode']),

        unknownItemName() {
            if (this.contact.id) {
                return ""
            }

            if (this.item.name && this.item.name.length) {
                return this.item.name
            }

            if (this.item.isAnonymous()) {
                return this.$t("softphone.caller-id-anonymous")
            }

            return this.formattedNumber
        },

        contact() {
            return this.getContactByNumber(this.item.number)
        },

        formattedNumber() {
            return formatNumber(this.item.number)
        }
    },
    methods: {
        onRowClick() {
            if (breakpoints.isTabletOrMobile) {
                this.expand(EXPANDED_TABS.MOBILE)
            }
        },

        expand(tabType) {
            this.$emit("expand", tabType)
        },

        showAddContactPopup,
        showEditContactPopup,
        speedDial,
        copyNumber,
        showTooltip,
        copyNameWithNumber
    }
}
</script>

<style scoped>

</style>