<template>
    <auth-layout>
        <template v-slot:right>
            <chip
                size="big"
                text="login.integrations.auth-prompt"
                color="white"
                radius="20px"
                width="100%"
                align="center"
                style="position: absolute; top: -66px;"
            ></chip>

            <!-- Email input -->
            <auth-input
                v-model="email"
                @input="error = false; $v.$reset()"
                @blur="$v.email.$touch()"
                :error="!!(error || emailNotValid)"
                autocomplete
                label="login.email-label"
            ></auth-input>

            <!-- Password input -->
            <auth-input
                v-model="password"
                @input="error = false"
                @keyup.enter.native="integrationsAuth()"
                :error="!!error"
                autocomplete
                type="password"
                label="login.password-label"
            ></auth-input>

            <auth-button
                @click="integrationsAuth"
                :disabled="$v.$invalid"
                :loading="loading"
                text="login.integrations.auth-button"
            ></auth-button>

            <auth-errors :errors="errors"></auth-errors>
        </template>
    </auth-layout>
</template>

<script>
import { required, email } from 'vuelidate/dist/validators.min.js'
import AuthLayout from "./layout/AuthLayout.vue";
import Chip from "../defaults/Chip.vue";
import BaseInput from "../defaults/BaseInput.vue";
import BaseButton from "../defaults/BaseButton.vue";
import {getCaptchaToken} from "../../helpers/CaptchaHelper";
import {mapActions, mapGetters} from "vuex";
import AuthInput from "./base/AuthInput.vue";
import AuthButton from "./base/AuthButton.vue";
import AuthErrors from "./base/AuthErrors.vue";

export default {
    name: "IntegrationsLogin",
    components: {AuthErrors, AuthButton, AuthInput, BaseButton, BaseInput, Chip, AuthLayout},
    data() {
        return {
            email: '',
            password: '',
            loading: false,
            error: false
        }
    },
    validations: {
        email: {
            required,
            email
        },
        password: {
            required
        }
    },
    computed: {
        emailNotValid() {
            return this.$v.email.$error && this.email.length
        },

        errors() {
            if (this.emailNotValid) {
                return [this.$t("login.please-enter-valid-email")]
            }

            if (this.error) {
                return [this.$t("login." + this.error)]
            }

            return []
        }
    },
    methods: {
        ...mapActions('user', ['integrationsLogIn']),
        ...mapActions(['updateLogoUrl', 'updateFaviconLogoUrl']),

        integrationsAuth() {
            if (this.$v.$invalid || this.loading) {
                return
            }

            const clientId = this.$route.query.client_id
            const state = this.$route.query.state
            const redirectUri = this.$route.query.redirect_uri
            const email = this.email
            const password = this.password

            this.loading = true
            this.error = false

            getCaptchaToken('login')
                .then((token) => {
                    const captcha = token
                    this.integrationsLogIn({ email, password, captcha, clientId, state, redirectUri })
                        .then((response) => {
                            if (response.data.darkLogoUrl) {
                                this.updateLogoUrl(response.data.darkLogoUrl)
                            }
                            if (response.data.favicon32x32Url) {
                                this.updateFaviconLogoUrl(response.data.favicon32x32Url)
                            }

                            window.open(response.data["redirect_uri"], "_self")
                        })
                        .catch((e) => {
                            this.error = 'invalid-username-or-password'
                        }).finally(() => this.loading = false)
                })
                .catch(() => {
                    this.loading = false
                    this.error = 'invalid-captcha'
                })
        }
    }
}
</script>

<style scoped>

</style>