<template>
    <div class="contact">
        <div class="contact-avatar">
            <slot name="avatar">
                <contact-avatar
                    :contact="localContact"
                    :call-type="callType"
                ></contact-avatar>
            </slot>
        </div>

        <div class="contact-info">
            <slot name="info">
                <contact-info
                    :primary-info="localContact.id || contactName ? contactName : formattedNumber"
                    :secondary-info="localContact.id || contactName ? formattedNumber : ''"
                    :is-hover="isHover"
                    :color="color"
                ></contact-info>
            </slot>
        </div>
    </div>
</template>

<script>
import Avatar from "../Avatar.vue";
import CopyButton from "../CopyButton.vue";
import BaseTooltip from "../BaseTooltip.vue";
import {mapGetters} from "vuex";
import {formatNumber} from "../../../utils/phoneNumbers";
import {CONTACT_INFO_COLOR} from "./config";
import ContactAvatar from "./ContactAvatar.vue";
import ContactInfo from "./ContactInfo.vue";

export default {
    name: "Contact",
    components: {ContactInfo, ContactAvatar, Avatar, CopyButton, BaseTooltip},
    props: {
        // if a contact is passed, it will be used,
        // otherwise a contact search by number will be performed
        contact: {
            default() {
                return {}
            },
            type: Object
        },
        number: {
            default: "",
            type: String
        },
        color: {
            default: CONTACT_INFO_COLOR.LIGHT_COLOR,
            type: String
        },
        isHover: {
            default: false,
            type: Boolean
        },
        // if it is an unknown contact and this prop is not empty, then this name will be displayed for the contact
        unknownName: {
            default: "",
            type: String
        },
        callType: {
            default: "",
            type: String
        }
    },
    data() {
        return {
            copyButton: {
                'isCopied': false
            }
        }
    },
    computed: {
        ...mapGetters('details', [ 'getContactByNumber']),

        localContact() {
            return Object.keys(this.contact).length ? this.contact : this.getContactByNumber(this.number)
        },

        contactName() {
            if (this.localContact.id) {
                return this.localContact.name
            }

            if (!this.localContact.id && this.unknownName) {
                return this.unknownName
            }

            return ""
        },

        formattedNumber() {
            return this.localContact.number ? formatNumber(this.localContact.number) : formatNumber(this.number)
        }
    }
}
</script>

<style scoped>

</style>