<template>
    <base-dropdown
        @input="onInput"
        :value="value"
        :width="width"
        :position="position"
        open-by="click"
    >
        <template v-slot:activator>
            <download-popup
                v-model="downloadAndroidPopup"
                :download-url="androidAppDownloadLink"
                :qr-code-src="require('../../../images/qrCodes/download_android_app.png')"
                :title="$t('apps-dropdown.popup-titles.android-app')"
                :open-btn-text="$t('open', ['Google Play'])"
            ></download-popup>

            <download-popup
                v-model="downloadIosPopup"
                :download-url="iosAppDownloadLink"
                :qr-code-src="require('../../../images/qrCodes/download_ios_app.png')"
                :title="$t('apps-dropdown.popup-titles.ios-app')"
                :open-btn-text="$t('open', ['App Store'])"
            ></download-popup>

            <slot name="activator"></slot>
        </template>

        <template v-slot:no-scrollable-content>
            <base-list>
                <base-list-item
                    v-for="app in desktopApps"
                    @click="downloadApp(app)"
                    :key="app.type"
                >
                    <base-list-item-icon :icon="app.icon"></base-list-item-icon>

                    <base-list-item-title>
                        {{ app.name }}
                    </base-list-item-title>
                    <base-tooltip :text="'top-bar.menu.apps.' + app.type + '.hint'" :width="212" position="bottom" textAlign="center"></base-tooltip>
                </base-list-item>
            </base-list>

            <divider v-if="isMobileLinksAvailable"></divider>

            <base-list v-if="isMobileLinksAvailable">
                <base-list-item
                    v-for="app in mobileApps"
                    @click="downloadApp(app)"
                    :key="app.type"
                >
                    <base-list-item-icon :icon="app.icon"></base-list-item-icon>

                    <base-list-item-title>
                        {{ app.name }}
                    </base-list-item-title>
                </base-list-item>
            </base-list>
        </template>
    </base-dropdown>
</template>

<script>
import BaseTooltip from "../BaseTooltip.vue";
import BaseDropdown from "../BaseDropdown.vue";
import BaseList from "../baseList/BaseList.vue";
import BaseListItem from "../baseList/BaseListItem.vue";
import BaseListItemIcon from "../baseList/BaseListItemIcon.vue";
import BaseListItemTitle from "../baseList/BaseListItemTitle.vue";
import Divider from "../Divider.vue";
import DownloadPopup from "../popups/DownloadPopup.vue";
import {downloadFile} from "../../../utils/DOM";
import {mapGetters} from "vuex";

export default {
    name: "AppsDropdown",
    components: {DownloadPopup, Divider, BaseListItemTitle, BaseListItemIcon, BaseListItem, BaseList, BaseDropdown, BaseTooltip},
    props: {
        value: {
            type: Boolean
        },
        position: {
            type: String
        },
        width: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            downloadIosPopup: false,
            downloadAndroidPopup: false,
            isOpenedAppsDropdown: false
        }
    },
    computed: {
        ...mapGetters(['isMobileLinksAvailable']),

        ...mapGetters('apps', ['apps', 'mobileApps', 'desktopApps']),

        androidAppDownloadLink() {
            const app = this.apps.find((app) => app.isAndroid)

            return app ? app.link : ""
        },

        iosAppDownloadLink() {
            const app = this.apps.find((app) => app.isIos)

            return app ? app.link : ""
        }
    },
    methods: {
        /**
         * @param {App} app
         */
        downloadApp(app) {
            if (app.isAndroid) {
                this.downloadAndroidPopup = true
                return
            }

            if (app.isIos) {
                this.downloadIosPopup = true
                return
            }

            downloadFile(app.link, "")
        },

        onInput(value) {
            this.$emit("input", value)
        }
    }
}
</script>

<style scoped>

</style>