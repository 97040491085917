<template>
    <div class="call-history-table-expanded-row">
        <mobile-expanded-tab
            v-if="breakpoints.isTabletOrMobile"
            @expand="onMobileExpand"
            :item="item"
            :expanded-tab="expandedTabMobile"
        ></mobile-expanded-tab>

        <integrations-inline
            v-if="isIntegrationsExpanded"
            :number="item.number"
            :inline-call-log-data="callLogData"
        ></integrations-inline>

        <call-recordings-viewer
            v-if="isCallRecordingsExpanded"
            :call-id="item.callId"
        ></call-recordings-viewer>
    </div>
</template>

<script>
import BaseButton from "../../defaults/BaseButton.vue";
import IntegrationsInline from "../../integrations/IntegrationsInline.vue";
import CallRecordingsViewer from "./expandedTabs/CallRecordingsExpandedTab.vue";
import MobileExpandedTab from "./expandedTabs/mobileExpandedTab/MobileExpandedTab.vue";
import breakpoints from "../../../plugins/breakpoints";
import callHistoryTableRowMixin from "./callHistoryTableRowMixin";
import {EXPANDED_TABS} from "./config";
import dayjsOverride from "../../../helpers/dayjsOverride";

export default {
    name: "CallHistoryTableExpandedRow",
    components: {MobileExpandedTab, CallRecordingsViewer, IntegrationsInline, BaseButton},
    mixins: [callHistoryTableRowMixin],
    props: {
        expandedTab: {
            type: String,
            default: "",
            validator(value) {
                return [EXPANDED_TABS.CALL_RECORDINGS, EXPANDED_TABS.INTEGRATIONS, EXPANDED_TABS.MOBILE, ""].includes(value)
            }
        }
    },
    data() {
        return {
            expandedTabMobile: "",
            breakpoints,
            EXPANDED_TABS
        }
    },
    watch: {
        "breakpoints.isTabletOrMobile": function(value) {
            if (value) {
                this.expandedTabMobile = this.expandedTab
                this.$emit("expand", EXPANDED_TABS.MOBILE)
                return
            }

            if (this.expandedTabMobile) {
                this.$emit("expand", this.expandedTabMobile)
                this.$nextTick(() => this.expandedTabMobile = "")
            }
        }
    },
    computed: {
        isIntegrationsExpanded() {
            return [this.expandedTab, this.expandedTabMobile].includes(EXPANDED_TABS.INTEGRATIONS)
        },

        isCallRecordingsExpanded() {
            return [this.expandedTab, this.expandedTabMobile].includes(EXPANDED_TABS.CALL_RECORDINGS)
        },

        callLogData() {
            return {
                seconds: this.item.duration,
                direction: this.item.isOutgoing() ? "out" : "in",
                number: this.item.number,
                startTimestamp: dayjsOverride(this.item.date).utc().valueOf(),
                cid: this.item.callerid
            }
        }
    },
    methods: {
        onMobileExpand(type) {
            if ([EXPANDED_TABS.CALL_RECORDINGS, EXPANDED_TABS.INTEGRATIONS].includes(type)) {
                this.expandedTabMobile = this.expandedTabMobile === type ? "" : type
            }
        }
    }
}
</script>

<style scoped>

</style>