<template>
    <auth-layout class="forgot-password">
        <template v-slot:right>
            <template v-if="success">
                <p>{{ $t("login.send-email-success") }}</p>

                <auth-button
                    @click="backToLogin"
                    text="login.back-to-login"
                ></auth-button>
            </template>

            <template v-else>
                <p>{{ $t("login.reset-info") }}</p>

                <!-- Email input -->
                <auth-input
                    v-model="email"
                    @input="$v.$reset()"
                    @blur="$v.email.$touch()"
                    :error="!!emailNotValid"
                    autocomplete
                    label="login.reset-email-label"
                ></auth-input>

                <!-- Buttons -->
                <div class="buttons-group">
                    <auth-button
                        @click="reset"
                        :disabled="$v.email.$invalid"
                        :loading="loading"
                        text="login.reset-password-button"
                    ></auth-button>

                    <base-button
                        class="text-btn"
                        @click="backToLogin"
                        text="Back"
                        color="text"
                        style="font-size: 14px"
                    ></base-button>
                </div>

                <auth-errors :errors="errors"></auth-errors>
            </template>
        </template>

        <template v-slot:footer>
            <login-footer/>
        </template>
    </auth-layout>
</template>

<script>
import { required, email } from 'vuelidate/dist/validators.min.js'
import AuthLayout from "./layout/AuthLayout.vue";
import BaseInput from "../defaults/BaseInput.vue";
import BaseButton from "../defaults/BaseButton.vue";
import {getCaptchaToken} from "../../helpers/CaptchaHelper";
import {mapActions, mapGetters} from "vuex";
import LoginFooter from "./base/LoginFooter.vue";
import AuthErrors from "./base/AuthErrors.vue";
import AuthInput from "./base/AuthInput.vue";
import AuthButton from "./base/AuthButton.vue";

export default {
    name: "ForgotPassword",
    components: {AuthButton, AuthInput, AuthErrors, LoginFooter, BaseButton, BaseInput, AuthLayout},
    data() {
        return {
            email: '',
            loading: false,
            success: false,
        }
    },
    validations: {
        email: {
            required,
            email
        }
    },
    computed: {
        ...mapGetters(["partnerId"]),

        emailNotValid() {
            return this.$v.email.$error && this.email.length
        },

        errors() {
            const result = []

            if (this.emailNotValid) {
                result.push("login.please-enter-valid-email")
            }
        }
    },
    methods: {
        ...mapActions('user', ['sendResetLink']),

        reset() {
            if (this.$v.email.$invalid || this.loading) {
                return
            }
            this.loading = true
            const email = this.email
            const partnerId = this.partnerId

            getCaptchaToken('password_reset_request')
                .then((token) => {
                    const captcha = token
                    this.sendResetLink({ email, captcha, partnerId })
                        .then(() => this.success = true)
                        .catch(() => {})
                        .finally(() => {
                            this.loading = false
                        })
                })
                .catch(() => {
                    this.loading = false
                })
        },

        backToLogin() {
            this.$router.push("/login")
        }
    }
}
</script>

<style scoped>

</style>