import Api from '../../utils/api';

export default {
    namespaced: true,
    state: {
        is_two_factor_auth_required: false,
        is_totp_enabled: false
    },
    getters: {
        isNeedToSetupTwoFactorAuth: (state) => {
            return state.is_two_factor_auth_required && !state.is_totp_enabled
        }
    },
    actions: {
        getTwoFactorAuthStatus: (context) => {
            return Api().get("/2fa/status")
                .then((response) => {
                    context.commit("setTwoFactorAuthStatus", {
                        isTwoFactorAuthRequired: response.data.is_2fa_required,
                        isTotpEnabled: response.data.is_totp_enabled
                    })
                })
        }
    },
    mutations: {
        setTwoFactorAuthStatus: (state, {isTwoFactorAuthRequired, isTotpEnabled}) => {
            state.is_two_factor_auth_required = isTwoFactorAuthRequired
            state.is_totp_enabled = isTotpEnabled
        }
    }
}