<template>
    <div class="contact-info" :class="classes">
        <base-tooltip v-model="copyButton.isCopied" :open-by="'manual'" text="copy-message" position="top" textAlign="center" :width="70">
            <template v-slot:activator>
                <div class="contact-info-group">
                    <span class="contact-info-primary">{{ primaryInfo }}</span>

                    <span v-if="secondaryInfo" class="contact-info-secondary">{{ secondaryInfo }}</span>
                </div>
            </template>
        </base-tooltip>

        <copy-button
            v-if="isHover"
            @click="onClickCopyButton"
        ></copy-button>
    </div>
</template>

<script>
import BaseTooltip from "../BaseTooltip.vue";
import CopyButton from "../CopyButton.vue";
import {copyValue, showTooltip, copyNumber, copyNameWithNumber} from "../../../utils/copy";
import {getPhoneNumberDigitsOnly, isPhoneNumber} from "../../../utils/phoneNumbers";
import {CONTACT_INFO_COLOR} from "./config";

export default {
    name: "ContactInfo",
    components: {CopyButton, BaseTooltip},
    props: {
        primaryInfo: {
            type: String,
            required: true,
            default: ""
        },
        secondaryInfo: {
            type: String,
            default: ""
        },
        isHover: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: CONTACT_INFO_COLOR.LIGHT_COLOR
        },
        // By default "primaryInfo + secondaryInfo" is copied
        // If primaryInfo or secondaryInfo is a phone number, only digits will be copied
        copyValue: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            copyButton: {
                'isCopied': false
            }
        }
    },
    computed: {
        classes() {
            return {
                "contact-info-light-color": this.color === CONTACT_INFO_COLOR.LIGHT_COLOR,
                "contact-info-dark-color": this.color === CONTACT_INFO_COLOR.DARK_COLOR
            }
        },

        _copyValue() {
            let firstPartCopy = this.primaryInfo
            let secondPartCopy = this.secondaryInfo

            if (isPhoneNumber(this.primaryInfo)) {
                firstPartCopy = getPhoneNumberDigitsOnly(this.primaryInfo) || this.primaryInfo
            }

            if (this.secondaryInfo && isPhoneNumber(this.secondaryInfo)) {
                secondPartCopy = getPhoneNumberDigitsOnly(this.secondaryInfo) || this.secondaryInfo
            }

            return `${firstPartCopy} ${secondPartCopy}`.trim()
        }
    },
    methods: {
        onClickCopyButton() {
            copyValue(this.copyValue || this._copyValue)
            showTooltip(this.copyButton, 'isCopied')
        }
    }
}
</script>

<style scoped>

</style>