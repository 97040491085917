<template>
    <div class="call-history-mobile-expanded-tab">
        <!-- Contact number -->
        <mobile-expanded-tab-row
            :title="$t('panel.call-history.expanded.phone-number')"
        >
            <template v-slot:value>
                <base-button
                    v-if="!contact.id"
                    @click.native.stop="showAddContactPopup(item.number)"
                    size="medium"
                    color="table"
                    :text="formattedNumber"
                    icon="icon-person-add"
                    class="entry-expanded-button add-contact-btn"
                    style="padding: 3px 7px;"
                ></base-button>

                <base-button
                    v-if="contact.id"
                    @click.native.stop="showEditContactPopup(contact)"
                    size="medium"
                    color="table"
                    :text="formattedNumber"
                    class="entry-expanded-button"
                    style="padding: 3px 7px;"
                ></base-button>
            </template>
        </mobile-expanded-tab-row>

        <!-- Callerid name -->
        <mobile-expanded-tab-row
            v-if="item.calleridName"
            :value="item.calleridName"
            title="Caller ID name"
        ></mobile-expanded-tab-row>

        <!-- Callerid prefix -->
        <mobile-expanded-tab-row
            v-if="item.calleridPrefix"
            :title="$t('panel.call-history.expanded.caller-id-prefix')"
            :value="item.calleridPrefix"
        ></mobile-expanded-tab-row>

        <!-- Callerid -->
        <mobile-expanded-tab-row
            :title="$t('panel.call-history.expanded.line-used')"
            :value="formattedCalleridNumber"
        ></mobile-expanded-tab-row>

        <!-- Call duration -->
        <mobile-expanded-tab-row
            :title="$t('panel.call-history.expanded.duration')"
            :value="callDuration"
        ></mobile-expanded-tab-row>

        <!-- Actions -->
        <div class="call-history-mobile-expanded-tab-actions">
            <base-button
                v-if="canShowIntegrations && !isExtension && !item.isAnonymous()"
                @click.native.stop="onExpand(EXPANDED_TABS.INTEGRATIONS)"
                size="medium"
                :color="expandedTab === EXPANDED_TABS.INTEGRATIONS ? 'dark-gray' : 'table'"
                icon="icon-join-right-black"
                class="show-crms-btn"
            ></base-button>

            <base-button
                v-if="item.isCallRecordingsExist"
                @click.native.stop="onExpand(EXPANDED_TABS.CALL_RECORDINGS)"
                size="medium"
                :color="expandedTab === EXPANDED_TABS.CALL_RECORDINGS ? 'dark-gray' : 'table'"
                icon="icon-mic"
                class="show-records-btn"
            ></base-button>
        </div>
    </div>
</template>

<script>
import BaseButton from "../../../../defaults/BaseButton.vue";
import MobileExpandedTabRow from "./MobileExpandedTabRow.vue";
import {EXPANDED_TABS} from "../../config";
import {mapGetters} from "vuex";
import {formatNumber} from "../../../../../utils/phoneNumbers";
import {showAddContactPopup, showEditContactPopup} from "../../../../../utils/phonebooks";
import callHistoryTableRowMixin from "../../callHistoryTableRowMixin";

export default {
    name: "MobileExpandedTab",
    components: {BaseButton, MobileExpandedTabRow},
    mixins: [callHistoryTableRowMixin],
    props: {
        expandedTab: {
            type: String,
            default: "",
            validator(value) {
                return [EXPANDED_TABS.CALL_RECORDINGS, EXPANDED_TABS.INTEGRATIONS, ""].includes(value)
            }
        }
    },
    data() {
        return {
            EXPANDED_TABS
        }
    },
    computed: {
        ...mapGetters("details", ["getContactByNumber"]),

        contact() {
            return this.getContactByNumber(this.item.number)
        },

        formattedNumber() {
            return this.item.isAnonymous() ? this.$t("softphone.caller-id-anonymous") : formatNumber(this.item.number)
        },

        formattedCalleridNumber() {
            return formatNumber(this.item.callerid)
        }
    },
    methods: {
        onExpand(tabType) {
            this.$emit("expand", tabType)
        },

        showAddContactPopup,
        showEditContactPopup
    }
}
</script>

<style scoped>

</style>