<template>
    <tr class="call-parking-item-table-row" @mouseover="isHover = true" @mouseleave="isHover = false">
        <td class="call-parking-item-table-row-cell">
            <template v-if="isWallboard">{{ item.number }}</template>

            <base-button
                v-else
                @click="onCallClick"
                :text="item.number.toString()"
                size="medium"
                color="table"
                icon="icon-call"
                class="call-btn"
            ></base-button>
        </td>

        <td v-if="!breakpoints.isMobile" class="call-parking-item-table-row-cell">
            <contact
                v-if="item.parked_by_number"
                :number="item.parked_by_number"
                :is-hover="isHover"
            ></contact>

            <span v-else>{{ $t("panel.services.call-parking.incoming-call") }}</span>
        </td>

        <td class="call-parking-item-table-row-cell">
          {{ endInText }}
        </td>

        <td class="call-parking-item-table-row-cell">
            <contact
                :number="item.parked_number"
                :is-hover="isHover"
                :unknown-name="contactNameInCrm(item.parked_number)"
            ></contact>
        </td>
    </tr>
</template>

<script>
import {speedDial} from "../../../../utils/softphone";
import BaseButton from "../../../defaults/BaseButton.vue";
import Contact from "../../../defaults/contact/Contact.vue";
import breakpoints from "../../../../plugins/breakpoints";
import {mapGetters} from "vuex";

export default {
    name: "CallParkingItemTableRow",
    components: {Contact, BaseButton},
    props: {
        item: {
            type: Object,
            default: {}
        },
        isWallboard: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            isHover: false,
            currentTime: this.calcStartCurrentTime(),
            timer: null,
            breakpoints
        }
    },
    created() {
        if (this.currentTime === 0) {
            this.parkTimeIsOver()
        }
    },
    watch: {
        currentTime(value) {
            if (value === 0) {
                this.parkTimeIsOver()
            }
        }
    },
    computed: {
        ...mapGetters("integrations", ["contactNameInCrm"]),

        endInText() {
            const totalMinutes = Math.floor(this.currentTime / 60)

            if (totalMinutes > 0) {
                return `${totalMinutes}m`
            }

            return `${this.currentTime}s`
        }
    },
    mounted() {
        this.startTimer()
    },
    destroyed() {
        this.stopTimer()
    },
    methods: {
        calcStartCurrentTime() {
            return Math.max(0, this.item.park_end_in_seconds - Math.floor((Date.now() - this.item.park_started_at_timestamp) / 1000))
        },

        startTimer() {
            this.timer = setInterval(() => {
                this.currentTime--
            }, 1000)
        },

        stopTimer() {
            clearTimeout(this.timer)
        },

        parkTimeIsOver() {
            this.$emit("park-time-is-over")
            this.stopTimer()
        },

        onCallClick() {
            speedDial(this.item.number.toString(), null, this.item.parked_number)
        }
    }
}
</script>

<style scoped>

</style>