<template>
    <div class="call-recordings-expanded-tab">
        <base-spinner v-if="loading" :size="'medium'" :color="'blue'"></base-spinner>

        <p v-if="!loading && !callRecordingsExist">No call recordings</p>

        <audio-player
            v-if="!loading && callRecordingsExist"
            v-for="callRecording in callRecordings(callId)"
            @get-audio-url="getAudioUrl(callRecording.id, callRecording.rec_start_at, $event.resolve)"
            :key="'record-player-' + callRecording.id"
            :audio-duration="callRecording.rec_duration"
        ></audio-player>
    </div>
</template>

<script>
import BaseSpinner from "../../../defaults/BaseSpinner.vue";
import AudioPlayer from "../../../defaults/AudioPlayer.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "CallRecordingsExpandedTab",
    components: {AudioPlayer, BaseSpinner},
    props: {
        callId: {
            type: [String, Number],
            default: ""
        }
    },
    data() {
        return {
            loading: true
        }
    },
    created() {
        if (this.callId) {
            this.getCallRecordings(this.callId)
                .catch(() => {})
                .finally(() => this.loading = false)
        }
    },
    destroyed() {
        if (this.callId) {
            this.resetCallRecordings(this.callId)
        }
    },
    computed: {
        ...mapGetters('callRecordings', ['callRecordings']),

        callRecordingsExist() {
            return Boolean(this.callRecordings(this.callId).length)
        }
    },
    methods: {
        ...mapActions('callRecordings', ['getCallRecordings', 'resetCallRecordings', 'getCallRecordingUrl']),

        getAudioUrl(callRecordingId, recStartAt, resolve) {
            this.getCallRecordingUrl({callRecordingId, recStartAt}).then((url) => {
                resolve(url)
            }).catch(() => {})
        }
    }
}
</script>

<style scoped>

</style>