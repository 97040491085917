<template>
    <div v-if="!isLoading" class="app">
        <div v-if="appNoLicense" class="app-no-license">
            <img src="../images/app_no_license.svg">
            <h3>{{ $t("alert-messages.no-license.error-text") }}</h3>
            <p>{{ $t("alert-messages.no-license.solution-1") }}</p>
            <ul>
              <li>{{ $t("alert-messages.no-license.solution-li-1") }}</li>
              <li>{{ $t("alert-messages.no-license.solution-li-2") }}</li>
            </ul>
            <p>{{ $t("alert-messages.no-license.solution-2") }}</p>
          <base-button
              @click="goBack"
              :text="'alert-messages.no-license.go-back-button'"
              :size="'big'"
              :color="'white'"
          ></base-button>
        </div>
        <div v-else-if="appActive" class="app">
            <router-view></router-view>
        </div>
        <div v-else class="app-inactive">
            <img src="../images/app_inactive.svg">
            <p>{{ $t("alert-messages.current-session-no-active") }}</p>
            <p>{{ $t("alert-messages.current-session-no-active-solution") }}</p>
        </div>
        <div v-show="alertMessages.length" class="alerts-wrapper">
            <alert
                v-for="(item, index) in alertMessages"
                :key="index"
                :type="item.type"
                :header-text="item.headerText"
                :message="item.message"
                :button-text="item.buttonText"
                @onClose="removeAlertMessage({index, autoRemove: false}); item.closeHandler ? item.closeHandler() : null"
                @buttonClick="item.buttonHandler ? item.buttonHandler() : null"
                @outsideClick="item.outsideClickHandler ? item.outsideClickHandler() : null"
            ></alert>
        </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Api from '../utils/api';
import i18n from "../locales";
import Alert from "./defaults/Alert.vue";
import beep from '../sounds/beep.mp3';
import beepNotAnswered from '../sounds/not_answered.mp3';
import callWaiting from '../sounds/call_waiting.mp3'
import {NotificationHelper} from "../helpers/notificationHelper";
import Vue from "vue";
import {addElectronListener, sendToElectron, isElectronExist} from "../utils/electron";
import {
    canShowIntegrations,
    fetchCallInfo,
    logTheCall,
    onlyZapierEnabled,
    prepareLogDataAfterCallEnds
} from "../utils/integrations";
import {applyCaptcha} from "../helpers/CaptchaHelper";
import Crm from "../helpers/integrations/crms";
import {ZAPIER_TYPE} from "../helpers/integrations/crms";
import {CALL_DIRECTION_INCOMING, CALL_DIRECTION_OUTGOING, CALL_STATUS_ANSWERED, CALL_STATUS_UNANSWERED} from "../helpers/integrations/crms/crmTypes/ZapierCrm"
import BaseButton from "./defaults/BaseButton.vue";
import SocketIOHelper from "../helpers/SocketIOHelper";
import {authLogOut} from "../utils/auth";
import {getNationalNumberLength, getPhoneNumberDigitsOnly, isExtension, numbersAreMatched} from "../utils/phoneNumbers";
import calleridsOptionsMixin from "../mixins/calleridsOptionsMixin";
import App from "../models/App";
import {isTouchDevice} from "../utils/DOM";

export default {
    name: "App",
    components: {Alert, BaseButton},
    mixins: [calleridsOptionsMixin],
    data() {
        return {
            audio: null,
            ringDeviceId: 'default',
            speakerDeviceId: 'default'
        }
    },
    computed: {
        ...mapGetters(['alertMessages', 'logoUrl', 'isLoading','appActive', 'appNoLicense', 'faviconLogoUrl']),
        ...mapGetters('softphone', [
            'getPrimarySession',
            'getSecondSession',
            'getIsNotificationEnabled',
            'isIncomingSecond',
            'getSessions',
            'isIncomingIncoming',
            'isOutgoingIncoming',
            'isIncoming',
            'isInCall',
            'getIsCallRecordingAllowed',
            'getSipLogin'
        ]),
        ...mapGetters('integrations', ['callLogBuffer', 'zapierEnabled', 'integrations']),
        ...mapGetters('details', ['getContactsByNumber', 'extensions', 'getContactByNumber']),
        ...mapGetters('user', ['isOnline', 'isLoggedIn', 'user', 'ringtoneId', 'ringtones', 'callerIds', 'crmTenantName', 'email']),
        ...mapGetters('callParkings', ['getSlot', 'callParkings']),

        ringtone() {
            const ringtone = this.ringtones.find((ringtone) => ringtone.id === this.ringtoneId)

            if (!ringtone) {
                return require(`../sounds/ringtones/yarra.mp3`)
            }

            return require(`../sounds/ringtones/${ringtone.filename}`)
        }
    },
    watch: {
        alertMessages(messages) {
            this.clearAlerts(messages.length - 1)
        },
        faviconLogoUrl(url) {
            const iconElement = document.getElementById('page-icon')
            iconElement.href = url
        },
        extensions() {
            this.emitBuildExtensions()
        }
    },
    created() {
        const vm = this;
        const notificationHelper = new NotificationHelper()

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', () => {
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        });

        var url = new URL(window.location.href);
        var partnerId = url.searchParams.get("partnerId");

        Api().get('/auth/page_config', {
          params: {
            'partner_id': partnerId
          }})
          .then(response => {
            let partnerId = response.data.partner_id;

            const defaultLogo = require('../images/logo.svg')
            let logoUrl = response.data.darkLogoUrl ? response.data.darkLogoUrl : defaultLogo;

            let faviconLogoUrl = response.data.favicon32x32Url ? response.data.favicon32x32Url : defaultLogo;
            const captchaKey = response.data.captcha_v3_site_key;
            const isUniversalWebphone = response.data.is_universal_webphone;
            const isMobileLinksAvailable = response.data.isMobileLinksAvailable ? response.data.isMobileLinksAvailable : false;

            if (partnerId && typeof partnerId == 'string') {
              partnerId = partnerId.replace(/\D/g, '');
            }

            if (captchaKey) {
                applyCaptcha(captchaKey, isUniversalWebphone)
            }

            vm.updateLogoUrl(logoUrl);
            vm.updateFaviconLogoUrl(faviconLogoUrl);
            vm.updatePartnerId(partnerId);
            vm.updateIsUniversalWebphone(isUniversalWebphone);
            vm.updateIsMobileLinksAvailable(isMobileLinksAvailable);

            if (response.data.env_name && response.data.env_name.indexOf('prod') == -1) {
              Vue.config.devtools = true;
            } else {
              Vue.config.devtools = false;
            }

            const appsPaths = response.data.apps
            let apps = []
            Object.keys(appsPaths).forEach((type) => {
                let link = appsPaths[type]
                if (link) {
                    apps.push(new App({type, link}))
                }
            })

            vm.setApps(apps)
          })
          .catch(() => {})

        Api().get('/status')
            .then(response => {
                if (response.data.status) {
                    vm.changeIsLogged(true)
                    vm.$router.currentRoute.name === 'login' ? vm.$router.push('/') : ''
                }
            })
            .catch(() => {})
            .finally(() => {
                vm.changeIsLoading(false)
                const element = document.getElementById('app___loading')
                element.style.display = 'none';
            })

        const zapierCrm = new Crm().create(ZAPIER_TYPE)

        document.addEventListener('callInit', function (event) {
            if (vm.zapierEnabled) {
                const sessionData = event.detail.sessionData
                const agentPhoneNumber = sessionData.status === CALL_DIRECTION_INCOMING ? sessionData.call_received_number : sessionData.preferred_caller_id
                zapierCrm.triggerCallStarted(sessionData.display_name, sessionData.status, agentPhoneNumber, Date.now()).catch(() => {})
            }

            if (vm.getIsCallRecordingAllowed) {
                vm.loadAutoCallRecordingsStates().catch(() => {})
            }
        })

        document.addEventListener('incomingCallInit', function (event) {

            if (vm.isOnline) {
                const sessionData = event.detail.sessionData
                const contact = vm.getContactByNumber(sessionData.call_received_number)

                vm.incomingCall({
                    sessionData,
                    callerIdNum: sessionData.call_received_number,
                    callerIdName: contact.id ? contact.name : sessionData.call_received_name
                })
                if (vm.isIncomingSecond) {
                    vm.playSound(callWaiting)
                } else {
                    vm.playSound(vm.ringtone, true)
                }
                if (!document.hasFocus() && vm.getIsNotificationEnabled) {
                    notificationHelper.createNotification(vm.getSecondSession)
                }
            }
        })

        document.addEventListener('stopRingSound', function () {
            vm.stopSound()
        })

        document.addEventListener('playRingSound', function () {
            vm.playSound(callWaiting)
        })

        document.addEventListener('incomingCallOver', function (event) {
            if (!vm.isIncomingIncoming) {
                vm.stopSound();
            }
            notificationHelper.hideNotification(event.detail.sessionId);

            if (!event.detail.sessionWasAnswered) {
                sendToElectron("missedCall")
            }
        })

        document.addEventListener('outgoingCallInit', function (event) {
            const sessionData = event.detail.sessionData
            const callerId = vm.calleridsOptions.find((callerId) => numbersAreMatched(callerId.number, sessionData.preferred_caller_id))
            vm.outgoingCall({
                sessionData: event.detail.sessionData,
                callerIdNum: callerId ? callerId.number : vm.$t("softphone.caller-id-anonymous"),
                callerIdName: callerId ? callerId.name : ""
            })
        })

        document.addEventListener('outgoingCallEstablishing', function (event) {
          vm.playSound(beep);
        })

        document.addEventListener('outgoingCallOver', function (event) {
            if (!vm.isOutgoingIncoming) {
                vm.stopSound();
            }
            if (event.detail.sessionWasNotAnswered && !vm.isOutgoingIncoming) {
                vm.playSound(beepNotAnswered)
                setTimeout(function () {
                    vm.stopSound();
                }, 3000)
            }
        })

        document.addEventListener('sessionEstablished', function(event) {
            vm.sessionEstablished(event.detail.sessionId)

            if (vm.zapierEnabled) {
                const session = vm.getSessions.find((session) => session.id === event.detail.sessionId)
                const direction = session.direction === "in" ? CALL_DIRECTION_INCOMING : CALL_DIRECTION_OUTGOING
                const agentPhoneNumber = session.didDetails.number
                zapierCrm.triggerCallAnswered(session.displayName, direction, agentPhoneNumber, session.startTimestamp, session.answerTimestamp).catch(() => {})
            }
        })

        document.addEventListener('sessionTerminated', function(event) {
            const sessionId = event.detail.sessionId
            const session = vm.getSessions.find((session) => session.id === sessionId)
            if (!session) {
                return
            }

            const number = session.number
            const callLogData = prepareLogDataAfterCallEnds(session)

            if (callLogData.length) {
                logTheCall(callLogData).catch(() => {}).finally(() => vm.resetCallLogBuffer({number}))
            }

            if (vm.zapierEnabled) {
                const callStatus = session.answerTimestamp ? CALL_STATUS_ANSWERED : CALL_STATUS_UNANSWERED
                const direction = session.direction === "in" ? CALL_DIRECTION_INCOMING : CALL_DIRECTION_OUTGOING
                const agentPhoneNumber = session.didDetails.number
                zapierCrm.triggerCallEnded(session.displayName, number, direction, agentPhoneNumber, session.startTimestamp, session.answerTimestamp, Date.now(), callStatus).catch(() => {})
            }

            if (session.direction === "in") {
                vm.updateSessionIsCanceled({isCanceled: event.detail.incomingCallCanceled, sessionId})
            }
            vm.sessionTerminated(sessionId)
        })

        document.addEventListener('conferenceTerminated', function(event) {
            const sessions = vm.getSessions

            sessions.forEach((session) => {
                let number = session.number
                let callLogData = prepareLogDataAfterCallEnds(session)

                if (callLogData.length) {
                    logTheCall(callLogData).catch(() => {}).finally(() => vm.resetCallLogBuffer({number}))
                }

                if (vm.zapierEnabled) {
                    const callStatus = session.answerTimestamp ? CALL_STATUS_ANSWERED : CALL_STATUS_UNANSWERED
                    const direction = session.direction === "in" ? CALL_DIRECTION_INCOMING : CALL_DIRECTION_OUTGOING
                    const agentPhoneNumber = session.didDetails.number
                    zapierCrm.triggerCallEnded(session.displayName, number, direction, agentPhoneNumber, session.startTimestamp, session.answerTimestamp, Date.now(), callStatus).catch(() => {})
                }
            })

            vm.conferenceTerminated()
        })

        document.addEventListener('registerSuccess', function(event) {
            vm.updateIsRegistered(true)
            sendToElectron("sipRegisterSuccess")
        })

        document.addEventListener('connectionError', function(event) {
            vm.updateIsRegistered(false)
            vm.createAlertMessage({type: 'warning', message: i18n.t('alert-messages.register-fail')})
            sendToElectron("sipRegisterFail")
        })

        document.addEventListener('errorNoMic', function(event) {
            vm.createAlertMessage({
                type: 'warning',
                headerText: i18n.t('alert-messages.no-mic.title'),
                message: i18n.t('alert-messages.no-mic.message')
            })
        })

        document.addEventListener('updateSpeakerDeviceId', function(event) {
            vm.speakerDeviceId = event.detail.deviceId

            if (!vm.isIncoming && vm.isInCall) {
                vm.changeSoundSinkId(vm.speakerDeviceId)
            }
        })

        document.addEventListener('updateRingDeviceId', function(event) {
            vm.ringDeviceId = event.detail.deviceId

            if (vm.isIncoming && vm.isInCall) {
                vm.changeSoundSinkId(vm.ringDeviceId)
            }
        })

        function idleLogout() {
            let timer;
            const idleTimeout = 86400000 // 24h in millis econds
            if (isTouchDevice()) {
                window.ontouchstart = resetTimer;
                window.ontouchmove = resetTimer;
            }

            window.onload = resetTimer;
            window.onmousemove = resetTimer;
            window.onmousedown = resetTimer;
            window.onclick = resetTimer;
            window.onkeydown = resetTimer;
            window.addEventListener('scroll', resetTimer, true);

            function unAuth() {
                if (vm.isLoggedIn) {
                    vm.ctxSipStop()
                    vm.logOut()
                        .catch(() => {})
                        .finally(() => {
                            authLogOut()
                        })
                }
            }

            function resetTimer() {
                clearTimeout(timer);
                timer = setTimeout(unAuth, idleTimeout);
            }
        }

        if (!isElectronExist()) {
            idleLogout();
        }

        addElectronListener("setAppVersion", (event, ver) => {
            this.updateElectronAppVersion(ver)
        })

        addElectronListener("updateAvailable", () => {
            this.updateElectronAppUpdateAvailable(true)
        })

        addElectronListener("debug", (event, value) => {
            console.log('DEBUG')
            console.log(value)
        })

        addElectronListener("setNumber", (event, number) => {
            this.updateElectronDialNumber(decodeURIComponent(number))
        })

        addElectronListener("acceptCall", (event, id) => {
            if (this.isIncomingIncoming) {
                const rejectSessionId = this.getPrimarySession.id === id ? this.getSecondSession.id : this.getPrimarySession.id

                this.acceptCall(id)
                this.hangUp(rejectSessionId)
            } else if (this.isIncomingSecond) {
                this.acceptCall(id)
                this.sessionSetHold({sessionid: this.getPrimarySession.id, manually: false})
            } else if (this.isOutgoingIncoming) {
                this.acceptCall(id)
                this.hangUp(this.getPrimarySession.id)
            } else {
                this.acceptCall(id)
            }
        })

        addElectronListener("rejectCall", (event, id) => {
            this.hangUp(id)
        })

        SocketIOHelper.socket.on('webphone_unlink', (data) => {
            if (isElectronExist() && this.getSipLogin == data.device_number) {
                authLogOut()
            }
        })
        SocketIOHelper.socket.on('webphone_logout', () => {
            if (!isElectronExist()) {
                authLogOut()
            }
        })
        SocketIOHelper.socket.on("update_extension_status", this.updateContactStatus)
        SocketIOHelper.socket.io.on("reconnect", this.onSocketReconnect)
        SocketIOHelper.socket.on("parked_call", (data) => {
            let newSlot = {
                number: parseInt(data.slot_number),
                parked_by_number: data.agent_number === '<unknown>' ? '' : data.agent_number,
                park_started_at_timestamp: data.time,
                park_end_in_seconds: parseInt(data.park_end_in_seconds),
                parked_number: data.callerid
            }
            this.addSlot({callParkingNumber: parseInt(data.park_number), newSlot: newSlot})

            if (!canShowIntegrations(data.callerid) || onlyZapierEnabled() || this.getContactByNumber(data.callerid).id) {
                return
            }

            fetchCallInfo({
                tenant: this.crmTenantName,
                email: this.email,
                number: getPhoneNumberDigitsOnly(data.callerid),
                crm_list: this.integrations.filter((crm) => crm.type !== ZAPIER_TYPE).map((crm) => crm.type).join(","),
                phone_digits_to_search: getNationalNumberLength(data.callerid),
                use_strict_search: isExtension(getPhoneNumberDigitsOnly(data.callerid))
            })
                .then((response) => {
                    const slot = this.getSlot(parseInt(data.park_number), parseInt(data.slot_number))
                    if (!slot) {
                        return
                    }

                    for (let item of response.data) {
                        if (item.contacts && item.contacts.length && item.contacts[0]) {
                            this.setContactNameInCrm({number: data.callerid, name: item.contacts[0].name})
                            break
                        }
                    }
                })
                .catch(() => {})
        })
        SocketIOHelper.socket.on("unparked_call", (data) => {
            const slot = this.getSlot(parseInt(data.park_number), parseInt(data.slot_number))
            if (slot) {
                this.removeContactNameInCrm(slot.parked_number)
            }

            this.removeSlot({callParkingNumber: parseInt(data.park_number), slotNumber: parseInt(data.slot_number)})
        })
    },
    destroyed() {
        SocketIOHelper.socket.removeAllListeners("update_extension_status")
        SocketIOHelper.socket.io.removeListener("reconnect", this.onSocketReconnect)
    },
    methods: {
        ...mapActions([
            'removeAlertMessage',
            'updateCaptchaKey',
            'updateLogoUrl',
            'updateFaviconLogoUrl',
            'updatePartnerId',
            'createAlertMessage',
            'changeIsLoading',
            'updateElectronAppVersion',
            'updateElectronAppUpdateAvailable',
            'updateIsUniversalWebphone',
            'updateAppNoLicense',
            'updateAppActive',
            'updateIsMobileLinksAvailable'
        ]),
        ...mapActions('user', ['changeIsLogged', 'logOut']),
        ...mapActions('softphone', [
            'sessionEstablished',
            'sessionTerminated',
            'updateIsRegistered',
            'incomingCall',
            'outgoingCall',
            'ctxSipStop',
            'conferenceTerminated',
            'updateElectronDialNumber',
            'acceptCall',
            'hangUp',
            'loadAutoCallRecordingsStates',
            'updateSessionIsCanceled',
            'sessionSetHold'
        ]),
        ...mapActions('integrations', ['resetCallLogBuffer', 'setContactNameInCrm', 'removeContactNameInCrm']),
        ...mapActions("callParkings", ["removeSlot", "addSlot"]),
        ...mapActions("apps", ["setApps"]),

        clearAlerts (index) {
            if (this.alertMessages.length) {
                setTimeout(() => {
                    this.removeAlertMessage({index, autoRemove: true})
                }, 10000)
            }
        },
        goBack () {
          this.updateAppNoLicense(false);
        },

        async playSound(sound, ring = false) {
            if (!this.audio) {
                this.audio = new Audio();
                this.audio.type = 'audio/mpeg';
                this.audio.playbackRate = 1;
                this.audio.currentTime = 0;
                this.audio.loop = true;
            }

            this.audio.volume = 0.7;
            this.audio.src = sound;

            console.log("this.ringDeviceId", this.ringDeviceId)

            try {
                await this.audio.setSinkId?.(ring ? this.ringDeviceId : this.speakerDeviceId)
            } catch (e) {
                console.error(e)
            }

            this.audio.play().catch((error) => {
                console.error('Failed to play sound');
                console.error(error.message);
                // commented because error is thrown not in the expected cases and alert message can be confuse
                // this.createAlertMessage({
                //     type: 'warning',
                //     headerText: i18n.t('alert-messages.failed-to-play-ringtone.title'),
                //     message: i18n.t('alert-messages.failed-to-play-ringtone.message'),
                //     buttonText: i18n.t('alert-messages.failed-to-play-ringtone.button'),
                //     notHide: true,
                //     buttonHandler: () => {window.open('https://support.voipcloud.online/hc/en-us/articles/4410790180367', '_blank').focus()}
                // })
            })
        },

        stopSound() {
            if (this.audio) {
                this.audio.pause();
                this.audio.currentTime = 0;
                this.audio.src = '';
            }
        },

        changeSoundSinkId(deviceId) {
            if (this.audio) {
                this.audio.setSinkId?.(deviceId)
                    .catch((error) => {
                        console.error(error)
                    })
            }
        },

        emitBuildExtensions() {
            SocketIOHelper.emit("build_extensions", this.extensions)
        },

        updateContactStatus(socketData) {
            const number = socketData.extension
            const status = Number(socketData.status)
            let contactStatus
            if (status === 0) {
                contactStatus = "contact-idle"
            } else if (status === 4 || status === -1 || status === -2) {
                contactStatus = "contact-unavailable"
            } else {
                contactStatus = "contact-busy"
            }

            const contacts = this.getContactsByNumber(number)
            contacts.forEach(contact => contact.status = contactStatus)
        },

        onSocketReconnect() {
            this.emitBuildExtensions()
            SocketIOHelper.emit("build_call_parkings", this.callParkings.map(p => p.number))
        }
    }
}

</script>
