<template>
    <div class="call-history-date-cell">
        <base-tooltip
            :text="tooltipText"
            :disabled="isExpanded && breakpoints.isTabletOrMobile"
            position="bottom"
            text-align="center"
        >
            <template v-slot:activator>
                <span>
                    {{ isExpanded && breakpoints.isTabletOrMobile ? callDateHint : callDate }}
                </span>
            </template>
        </base-tooltip>
    </div>
</template>

<script>
import breakpoints from "../../../../plugins/breakpoints";
import CallHistory from "../../../../models/CallHistory";
import dayjsOverride from "../../../../helpers/dayjsOverride";
import BaseTooltip from "../../../defaults/BaseTooltip.vue";
import {mapGetters} from "vuex";

export default {
    name: "CallHistoryDateCell",
    components: {BaseTooltip},
    props: {
        item: {
            type: CallHistory
        },
        isExpanded: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            breakpoints
        }
    },
    computed: {
        ...mapGetters("user", ["languageCode"]),

        callDate() {
            const dayjsObj = dayjsOverride(new Date(this.item.date)).locale(this.languageCode)
            const LLFormat = this.getLongDateFormatByBrowserLocale('LL')
            const LTFormat = this.getLongDateFormatByBrowserLocale("LT")

            return dayjsObj.calendar(null, {
                sameDay: '[' + dayjsObj.format(LTFormat) + ']',
                nextDay: 'DD.MM.YYYY HH:mm',
                nextWeek: 'DD.MM.YYYY HH:mm',
                lastDay: `[${this.$t("panel.call-history.yesterday-call")}]`,
                lastWeek: 'dddd',
                sameElse: '[' + dayjsObj.format(LLFormat) + ']'
            });

        },

        callDateHint() {
            const dayjsObj = dayjsOverride(new Date(this.item.date))
            const LLLFormat = this.getLongDateFormatByBrowserLocale('LLL')

            return dayjsObj.locale(this.languageCode).format(LLLFormat)
        },

        tooltipText() {
            let start = ""
            if (this.item.isOutgoing()) {
                start = this.$t("panel.call-history.call-date-outgoing")
            } else {
                start = this.$t("panel.call-history.call-date")
            }

            return `${start}\n${this.callDateHint}`
        }
    },

    methods: {
        getLongDateFormatByBrowserLocale(key) {
            const longDateFormat = dayjsOverride().localeData().longDateFormat(key)
            const regex = /\s\[.*?\]|\sг\.|\[-.*?\]/g // find all [de], [kl.], [-мӗшӗ], etc. and "г." in ru locale

            return longDateFormat.replace(regex, '')
        }
    }
}
</script>

<style scoped>

</style>