<template>
    <div class="tab phonebooks-tab" :class="{'active': this.active}">
        <div class="phonebooks-tab-header">
            <!-- Default phonebook select -->
            <base-select
                v-if="!breakpoints.isTabletOrMobile"
                v-model="selectedPhonebookId"
                :options="phonebooksOptions"
                :dropdown-max-height="340"
                class="select-phonebook"
            ></base-select>

            <!-- Phonebook select for mobile -->
            <base-dropdown
                v-if="breakpoints.isTabletOrMobile"
                :width="220"
                :max-height="340"
                position="bottom"
                align="left"
            >
                <template v-slot:activator>
                    <base-button
                        icon="icon-stories"
                        size="big"
                        class="select-phonebook"
                    ></base-button>
                </template>

                <base-list>
                    <base-list-item
                        v-for="(option, idx) in phonebooksOptions"
                        @click="selectedPhonebookId = option.value"
                        :selected="selectedPhonebookId === option.value"
                        :key="idx"
                    >
                        <base-list-item-title>{{ option.name }}</base-list-item-title>
                    </base-list-item>
                </base-list>
            </base-dropdown>

            <base-tooltip text="panel.phonebooks.select-phonebook-tooltip" :width="250" position="bottom"></base-tooltip>

            <!-- Search input -->
            <base-input
                v-model="search"
                :style="{'margin-right': search ? '12px' : 'auto'}"
                class="find-contact"
                placeholder="panel.phonebooks.find-contact-placeholder"
                icon="icon-search"
                type="text"
            ></base-input>

            <!-- Chip for search -->
            <chip
                v-if="search"
                :text="search"
                size="big"
                color="light-blue"
                class="search-chip"
            >
                <template v-slot:append>
                    <base-button
                        @click="clearSearch"
                        :icon-size="14"
                        size="small"
                        color="white"
                        icon="icon-clear"
                        radius="50%"
                        class="chip-button-close"
                    ></base-button>
                </template>
            </chip>

            <!-- Add new contact button -->
            <base-button
                @click="addContact"
                :icon="'icon-person-add2'"
                :text="breakpoints.isTabletOrMobile ? '' : 'panel.phonebooks.add-contact-button'"
                :color="'blue'"
                :size="'big'"
                class="add-contact-button"
            ></base-button>
        </div>

        <base-table
            :headers="_headers"
            :items="_contacts"
            :rounded="false"
            :loading="loading"
            :no-items-text="noItemsText"
            expandable-rows
            style="min-height: 0"
        >
            <template v-slot:row="{item, toggleRow, isExpanded}">
                <phonebooks-table-row
                    @expand="toggleRow(item.id)"
                    :is-expanded="isExpanded"
                    :key="item.id"
                    :item="item"
                    :selected-phonebook-id="selectedPhonebookId"
                ></phonebooks-table-row>
            </template>

            <template v-slot:expandedRow="{item}">
                <phonebooks-table-expanded-row
                    :number="item.number"
                ></phonebooks-table-expanded-row>
            </template>
        </base-table>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {findInContact} from "../../../utils/phonebooks";
import BaseSelect from "../../defaults/BaseSelect.vue";
import BaseButton from "../../defaults/BaseButton.vue";
import BaseInput from "../../defaults/BaseInput.vue";
import {showAddContactPopup} from "../../../utils/phonebooks";
import BaseSpinner from "../../defaults/BaseSpinner.vue";
import BaseTooltip from "../../defaults/BaseTooltip.vue";
import PhonebooksTableRow from "./PhonebooksTableRow.vue";
import Chip from "../../defaults/Chip.vue";
import phonebooksSelectOptionsMixin from "../../../mixins/phonebooks/phonebooksSelectOptionsMixin";
import BaseDropdown from "../../defaults/BaseDropdown.vue";
import BaseList from "../../defaults/baseList/BaseList.vue";
import BaseListItem from "../../defaults/baseList/BaseListItem.vue";
import BaseListItemTitle from "../../defaults/baseList/BaseListItemTitle.vue";
import breakpoints from "../../../plugins/breakpoints";
import BaseTable from "../../defaults/baseTable/BaseTable.vue";
import PhonebooksTableExpandedRow from "./PhonebooksTableExpandedRow.vue";

export default {
    name: "Phonebooks",
    components: {
        PhonebooksTableExpandedRow,
        BaseTable,
        BaseListItemTitle,
        BaseListItem,
        BaseList,
        BaseDropdown,
        Chip,
        PhonebooksTableRow,
        BaseTooltip, BaseSpinner, BaseInput, BaseButton, BaseSelect},
    props: {
      active: Boolean,
      loading: Boolean
    },
    mixins: [phonebooksSelectOptionsMixin],
    data() {
        return {
            search: '',
            selectedPhonebookId: null,
            breakpoints
        }
    },
    computed: {
        ...mapGetters('details', [
            'phonebooks',
            'contacts',
            'getIsReadOnlyPhonebook'
        ]),

        ...mapGetters('user', [
            'defaultPhonebookViewId'
        ]),

        isAllPhonebooks() {
            return this.selectedPhonebookId === "all"
        },

        selectedPhonebookIsReadOnly() {
            if (this.isAllPhonebooks) {
                return false
            }
            return this.getIsReadOnlyPhonebook(this.selectedPhonebookId)
        },

        _headers() {
            return [
                { title: this.$t('panel.phonebooks.header-name'), key: 'name', align: 'name', fr: 2, allowSort: true},
                { title: this.$t('panel.phonebooks.header-phone-number'), key: 'number', align: 'left', fr: 2, allowSort: true},
                { title: '', value: 'actions', align: 'right', fr: 1}
            ]
        },

        _contacts() {
            let result = []
            if (this.isAllPhonebooks) {
                result = this.contacts
            } else {
                result = this.contacts.filter(contact => contact.phonebook_id === this.selectedPhonebookId)
            }

            return result.filter((item) => findInContact(this.search, item))
        },

        noItemsText() {
            if (this.search) {
                return "panel.call-history.no-results-found"
            }

            return "panel.phonebooks.no-contacts-message"
        }
    },
    watch: {
        phonebooks() {
            this.selectedPhonebookId = this.defaultPhonebookViewId ? this.defaultPhonebookViewId : this.phonebooks[0].id
        }
    },
    methods: {
        addContact() {
            let phonebookId

            if (this.selectedPhonebookId === 'all' || this.selectedPhonebookIsReadOnly) {
                phonebookId = null
            } else {
                phonebookId = this.selectedPhonebookId
            }

            showAddContactPopup("", phonebookId)
        },

        clearSearch() {
            this.search = ""
        }
    }
}
</script>

<style scoped>

</style>