<template>
    <div class="two-factor-auth">
        <div>
            <h4 style="margin-bottom: 10px">{{ $t("two-factor-auth.title") }}</h4>

            <p>{{ $t("two-factor-auth.prompt") }}</p>
        </div>

        <auth-input
            v-model="totpPassword"
            @input="error = ''; $v.$reset()"
            @blur="$v.totpPassword.$touch()"
            @keyup.enter.native="onLogIn"
            :error="Boolean(totpPassword.length && $v.totpPassword.$invalid)"
            focused
        ></auth-input>

        <div>
            <auth-button
                @click="onLogIn"
                :disabled="$v.$invalid"
                :loading="loading"
                text="Log in"
                width="100%"
                style="margin-bottom: 16px"
            ></auth-button>

            <base-checkbox
                v-if="!isElectronExist()"
                v-model="isRememberMe"
                label="two-factor-auth.remember-me"
            ></base-checkbox>
        </div>

        <auth-errors :errors="[error]"></auth-errors>
    </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/dist/validators.min.js'
import AuthInput from "./base/AuthInput.vue";
import AuthButton from "./base/AuthButton.vue";
import BaseCheckbox from "../defaults/BaseCheckbox.vue";
import Api from "../../utils/api";
import {authLogIn} from "../../utils/auth";
import AuthErrors from "./base/AuthErrors.vue";
import {isElectronExist} from "../../utils/electron";
import {getCaptchaToken} from "../../helpers/CaptchaHelper";

export default {
    name: "TwoFactorAuth",
    components: {AuthErrors, BaseCheckbox, AuthButton, AuthInput},
    data() {
        return {
            totpPassword: "",
            isRememberMe: false,
            loading: false,
            error: ""
        }
    },
    validations: {
        totpPassword: {
            required,
            maxLength: maxLength(6),
            digit: (value) => /^[0-9]*$/.test(value)
        }
    },
    methods: {
        onLogIn() {
            this.loading = true

            getCaptchaToken("two_factor_login")
                .then((token) => {
                    Api().post("/2fa/login", {
                        totp_password: this.totpPassword,
                        is_remember_me: Number(this.isRememberMe),
                        captcha: token
                    })
                        .then(() => {
                            authLogIn()
                        })
                        .catch((error) => {
                            if (error?.response?.data?.code === "401") {
                                this.error = error.response.data.error_message
                            }
                        })
                        .finally(() => {
                            this.loading = false
                        })
                })
                .catch(() => {
                    this.loading = false
                    this.error = this.$t("login.invalid-captcha")
                })
        },

        isElectronExist
    }
}
</script>

<style scoped>

</style>