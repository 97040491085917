<template>
    <th class="base-table-header-item-wrapper">
        <div
            @click="onUpdateSort"
            class="base-table-header-item"
            :class="classes"
        >
            <slot>
                {{ header.title }}

                <span
                    v-if="header.allowSort && !isSorted"
                    class="icon icon-sort icon-arrow-down icon-allow-sort"
                ></span>
                <span
                    v-else-if="isSorted"
                    :class="{
                        'icon-arrow-down': header.sort === TABLE_HEADER_SORT.ASC,
                        'icon-arrow-up': header.sort === TABLE_HEADER_SORT.DESC
                    }"
                    class="icon icon-sort icon-sorted"
                ></span>
            </slot>
        </div>
    </th>
</template>

<script>
import TableHeader from "../../../models/baseTable/TableHeader";
import {TABLE_HEADER_SORT, TABLE_HEADER_ALIGN} from "../../../models/baseTable/TableHeader";

export default {
    name: "BaseTableHeaderItem",
    props: {
        header: {
            type: TableHeader
        }
    },
    data() {
        return {
            TABLE_HEADER_SORT
        }
    },
    computed: {
        classes() {
            const result = []

            if (this.header.align === TABLE_HEADER_ALIGN.CENTER) {
                result.push('base-table-header-item-align-center')
            } else if (this.header.align === TABLE_HEADER_ALIGN.RIGHT) {
                result.push('base-table-header-item-align-right')
            } else {
                result.push('base-table-header-item-align-left')
            }

            if (this.header.allowSort) {
                result.push('base-table-header-item-allow-sort')
            }

            if (this.header.className) {
                result.push(this.header.className)
            }

            return result
        },

        isSorted() {
            return this.header.allowSort && (this.header.sort === TABLE_HEADER_SORT.ASC || this.header.sort === TABLE_HEADER_SORT.DESC)
        }
    },
    methods: {
        onUpdateSort() {
            if (!this.header.allowSort) {
                return
            }

            if (this.header.sort === TABLE_HEADER_SORT.ASC) {
                this.$emit("update:sort", TABLE_HEADER_SORT.DESC)
            } else if (this.header.sort === TABLE_HEADER_SORT.DESC) {
                this.$emit("update:sort", TABLE_HEADER_SORT.DEFAULT)
            } else {
                this.$emit("update:sort", TABLE_HEADER_SORT.ASC)
            }
        }
    }
}
</script>

<style scoped>

</style>