<template>
    <div class="auth-layout">
        <div class="auth-layout-container">
            <div class="auth-layout-top">
                <slot name="top">
                    <div class="auth-layout-top-left">
                        <slot name="left">
                            <template v-if="!breakpoints.isMobile">
                                <div class="auth-layout-logo">
                                    <img :src="logoUrl">

                                    <h1>{{ $t("login.title") }}</h1>
                                </div>

                                <div class="auth-layout-captcha" v-if="captchaKey">
                                    This site is protected by reCAPTCHA and the Google
                                    <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
                                    <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
                                </div>
                            </template>
                        </slot>
                    </div>

                    <div class="auth-layout-top-right">
                        <slot name="right"></slot>
                    </div>
                </slot>
            </div>

            <div class="auth-layout-footer">
                <slot name="footer">
                    <base-select
                        @input="changeLocale($event)"
                        :value="$i18n.locale"
                        :options="languages"
                        position="top"
                        style="max-width: 160px"
                    ></base-select>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import BaseSelect from "../../defaults/BaseSelect.vue";
import BaseButton from "../../defaults/BaseButton.vue";
import AppsDropdown from "../../defaults/dropdowns/AppsDropdown.vue";
import {changeLocale} from "../../../locales";
import {isElectronExist} from "../../../utils/electron";
import breakpoints from "../../../plugins/breakpoints";

export default {
    name: "AuthLayout",
    components: {AppsDropdown, BaseButton, BaseSelect},
    data() {
        return {
            breakpoints
        }
    },
    computed: {
        ...mapGetters(['logoUrl', 'captchaKey', 'languages', 'electronAppVersion'])
    },
    methods: {
        changeLocale,
        isElectronExist
    }
}
</script>

<style scoped>

</style>