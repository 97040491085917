<template>
    <auth-layout class="login-page">
        <template v-slot:right>
            <two-factor-auth v-if="twoFactorAuth"></two-factor-auth>

            <template v-else>
                <base-button
                    v-if="electronAppUpdateAvailable"
                    @click="downloadUpdate"
                    text="login.update-available"
                    color="orange"
                    size="big"
                    radius="20px"
                    style="position: absolute; width: 100%; top: -66px"
                ></base-button>

                <!-- Email input -->
                <auth-input
                    v-model="email"
                    @input="error = false; $v.$reset()"
                    @blur="$v.email.$touch()"
                    :error="!!(error || emailNotValid)"
                    :label="$t('login.email-label')"
                    autocomplete
                ></auth-input>

                <!-- Password input -->
                <auth-input
                    v-model="password"
                    @input="error = false"
                    @keyup.enter.native="auth()"
                    :error="!!error"
                    :label="$t('login.password-label')"
                    type="password"
                    autocomplete
                ></auth-input>

                <!-- Buttons -->
                <div class="buttons-group">
                    <auth-button
                        @click="auth"
                        :disabled="$v.$invalid"
                        :loading="loading"
                        :width="breakpoints.isMobile ? 110 : 155"
                        text="login.login-button"
                    ></auth-button>

                    <base-button
                        class="text-btn"
                        @click="onForgotPasswordButtonClick"
                        text="login.forgot-password"
                        color="text"
                        style="font-size: 14px"
                    ></base-button>
                </div>

                <auth-errors :errors="errors"></auth-errors>
            </template>

            <two-factor-auth-setup-popup
                v-if="showTwoFactorAuthSetupPopup"
                v-model="showTwoFactorAuthSetupPopup"
            ></two-factor-auth-setup-popup>
        </template>

        <template v-slot:footer>
            <login-footer/>
        </template>
    </auth-layout>
</template>

<script>
import { required, email } from 'vuelidate/dist/validators.min.js'
import AuthLayout from "./layout/AuthLayout.vue";
import {mapActions, mapGetters} from "vuex";
import {sendToElectron, isElectronExist} from "../../utils/electron";
import BaseButton from "../defaults/BaseButton.vue";
import BaseInput from "../defaults/BaseInput.vue";
import {getCaptchaToken} from "../../helpers/CaptchaHelper";
import Api from "../../utils/api";
import LoginFooter from "./base/LoginFooter.vue";
import TwoFactorAuthSetupPopup from "../defaults/popups/TwoFactorAuthSetupPopup.vue";
import AuthInput from "./base/AuthInput.vue";
import AuthButton from "./base/AuthButton.vue";
import breakpoints from "../../plugins/breakpoints";
import AuthErrors from "./base/AuthErrors.vue";
import TwoFactorAuth from "./TwoFactorAuth.vue";
import {authLogIn} from "../../utils/auth";

export default {
    name: "Login",
    components: {
        TwoFactorAuth,
        AuthErrors,
        AuthButton, AuthInput, TwoFactorAuthSetupPopup, LoginFooter, BaseInput, BaseButton, AuthLayout},
    data() {
        return {
            email: '',
            password: '',
            loading: false,
            error: false,
            showTwoFactorAuthSetupPopup: false,
            twoFactorAuth: false,
            breakpoints
        }
    },
    validations: {
        email: {
            required,
            email
        },
        password: {
            required
        }
    },
    computed: {
        ...mapGetters(["electronAppUpdateAvailable", "partnerId"]),

        emailNotValid() {
            return this.$v.email.$error && this.email.length
        },

        errors() {
            const result = []

            if (this.emailNotValid) {
                result.push("login.please-enter-valid-email")
            }

            if (this.error) {
                result.push("login." + this.error)
            }

            return result
        }
    },
    methods: {
        ...mapActions(['updateLogoUrl', 'updateFaviconLogoUrl', 'updateAppNoLicense']),
        ...mapActions('user', ['logIn']),

        auth() {
            if (this.$v.$invalid || this.loading) {
                return
            }
            this.loading = true
            this.error = false
            const email = this.email
            const password = this.password
            const partnerId = this.partnerId

            getCaptchaToken('login')
                .then((token) => {
                    const captcha = token
                    this.logIn({ email, password, captcha, partnerId })
                        .then(response => {
                            if (response.data.darkLogoUrl) {
                                this.updateLogoUrl(response.data.darkLogoUrl)
                            }

                            if (response.data.favicon32x32Url) {
                                this.updateFaviconLogoUrl(response.data.favicon32x32Url)
                            }

                            this.proceedTwoFactorLogin()
                        })
                        .catch((err) => {
                            this.loading = false

                            if (err?.response?.data?.code === "403" && err?.response?.data?.error_message === "No license") {
                                this.updateAppNoLicense(true)
                            } else {
                                this.error = 'invalid-username-or-password'
                            }
                        })
                })
                .catch(() => {
                    this.loading = false
                    this.error = 'invalid-captcha'
                })
        },

        proceedTwoFactorLogin() {
            Api().get("/2fa/status")
                .then((response) => {
                    if (response.data.is_2fa_required && !response.data.is_totp_enabled) {
                        this.showTwoFactorAuthSetupPopup = true
                        return
                    }

                    if (response.data.is_2fa_required && response.data.is_totp_enabled && !response.data.is_2fa_verified) {
                        this.twoFactorAuth = true
                        return
                    }

                    authLogIn()
                })
                .catch(() => {})
                .finally(() => this.loading = false)
        },

        downloadUpdate() {
            sendToElectron("downloadUpdate")
        },

        onForgotPasswordButtonClick() {
            this.$router.push("/forgot-password")
        },

        isElectronExist
    }
}
</script>

<style scoped>

</style>