import CallHistory from "../../../models/CallHistory";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import {canShowIntegrations} from "../../../utils/integrations";
import {isExtension} from "../../../utils/phoneNumbers";

const callHistoryTableRowMixin = {
    props: {
        item: {
            type: CallHistory
        }
    },
    created() {
        dayjs.extend(duration)
    },
    computed: {
        callDuration() {
            const dayjsObj = dayjs.duration(this.item.duration * 1000)

            const format = dayjsObj.hours() > 0 ? "HH:mm:ss" : "mm:ss"
            return dayjs.duration({
                seconds: dayjsObj.seconds(),
                minutes: dayjsObj.minutes(),
                hours: dayjsObj.hours()
            }).format(format)
        },

        isExtension() {
            return isExtension(this.item.number)
        },

        canShowIntegrations() {
            return canShowIntegrations(this.item.number)
        }
    }
}

export default callHistoryTableRowMixin