<template>
    <base-popup
        v-if="value"
        @close="close"
        @save="onEnable"
        :width="350"
        :hide-default-actions="loading"
        :save-btn-disabled="$v.$invalid"
        :save-btn-loading="enableLoading"
        popup-class="two-factor-auth-setup-popup"
        title="Two-factor authentication setup"
        save-text="enable"
        close-text="cancel"
    >
        <template v-if="loading">
            <base-spinner></base-spinner>
        </template>

        <template v-else>
            <p>
                {{ $t("popups.two-factor-auth-setup.description") }}
            </p>

            <p>
                {{ $t("popups.two-factor-auth-setup.step.zero") }}
            </p>

            <p>
                {{ $t("popups.two-factor-auth-setup.step.one") }}
            </p>

            <div class="two-factor-auth-setup-popup-download-buttons">
                <base-button
                    @click="downloadIosPopup = true"
                    icon="icon-macos"
                    text="IOS"
                    size="big"
                ></base-button>

                <base-button
                    @click="downloadAndroidPopup = true"
                    icon="icon-android"
                    text="Android"
                    size="big"
                ></base-button>
            </div>

            <p>
                {{ $t("popups.two-factor-auth-setup.step.two") }}
            </p>

            <span v-if="secret" class="two-factor-auth-setup-popup-secret">{{ secret }}</span>

            <img v-if="qrCodeSrc" :src="qrCodeSrc" width="200" height="200" alt="QR code">

            <p>
                {{ $t("popups.two-factor-auth-setup.step.three") }}
            </p>

            <base-input
                v-model="totpPassword"
                @input="$v.$reset()"
                @blur="$v.totpPassword.$touch()"
                :error="$v.totpPassword.$invalid"
                :switch-password-visibility="false"
                class="two-factor-auth-setup-popup-password-input"
                style="width: 165px; margin-right: auto"
                type="password"
            ></base-input>
        </template>

        <template v-slot:actionsPrepend>
            <download-popup
                v-model="downloadIosPopup"
                title="popups.two-factor-auth-setup.ios-app"
                download-url="https://apps.apple.com/us/app/google-authenticator/id388497605"
                open-btn-text="Open App Store"
                :qr-code-src="require('../../../images/qrCodes/download_ios_google_auth.png')"
            ></download-popup>

            <download-popup
                v-model="downloadAndroidPopup"
                title="popups.two-factor-auth-setup.android-app"
                download-url="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                open-btn-text="Open Google Play"
                :qr-code-src="require('../../../images/qrCodes/download_android_google_auth.png')"
            ></download-popup>
        </template>
    </base-popup>
</template>

<script>
import BasePopup from "../BasePopup.vue";
import BaseButton from "../BaseButton.vue";
import BaseInput from "../BaseInput.vue";
import DownloadPopup from "./DownloadPopup.vue";
import BaseSpinner from "../BaseSpinner.vue";
import Api from "../../../utils/api";
import i18n from "../../../locales";
import {mapActions} from "vuex";
import { required, maxLength, minLength } from 'vuelidate/dist/validators.min.js'
import {authLogIn} from "../../../utils/auth";
import store from "../../../store";

export default {
    name: "TwoFactorAuthSetupPopup",
    components: {BaseSpinner, DownloadPopup, BaseInput, BaseButton, BasePopup},
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            totpPassword: "",
            secret: "",
            qrCodeSrc: "",
            downloadAndroidPopup: false,
            downloadIosPopup: false,
            loading: false,
            enableLoading: false
        }
    },
    validations: {
        totpPassword: {
            required,
            maxLength: maxLength(6),
            minLength: minLength(6),
            digit: (value) => /^[0-9]*$/.test(value)
        }
    },
    created() {
        this.loading = true

        Api().post("/2fa/setup")
            .then((response) => {
                this.secret = response.data.secret
                this.qrCodeSrc = response.data.qr_code
            })
            .catch(() => {})
            .finally(() => this.loading = false)
    },
    methods: {
        ...mapActions(["createAlertMessage"]),
        ...mapActions("twoFactorAuth", ["getTwoFactorAuthStatus"]),

        onEnable() {
            this.enableLoading = true
            Api().post("/2fa/enable", {totp_password: this.totpPassword})
                .then(() => {
                    this.createAlertMessage({type: 'success', message: i18n.t("popups.two-factor-auth-setup.success-enabled")})

                    this.close()

                    if (this.$router.history.current.name === "login") {
                        authLogIn()
                    } else {
                        this.getTwoFactorAuthStatus().catch(() => {})
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401 && error.response.data.error_message) {
                        store.dispatch('createAlertMessage', {message: error.response.data.error_message, type: 'warning'})
                    }
                })
                .finally(() => this.enableLoading = false)
        },

        close() {
            this.$emit('input', false)
        }
    }
}
</script>

<style scoped>

</style>