<template>
    <div class="phonebooks-phone-number-cell">
        <base-tooltip v-model="copyButton.isCopiedNumber" open-by="manual" text="copy-message" position="top" textAlign="center" :width="70">
            <template v-slot:activator>
                <div class="phonebooks-phone-number-cell-number" @click="speedDial(number)">
                    <span class="phone-icon icon-call"></span>
                    <span class="phone-number">{{ formattedNumber }}</span>
                </div>
            </template>
        </base-tooltip>

        <copy-button v-if="isHover" @click="[copyNumber(number), showTooltip(copyButton, 'isCopiedNumber')]"></copy-button>
    </div>
</template>

<script>
import BaseTooltip from "../../../defaults/BaseTooltip.vue";
import CopyButton from "../../../defaults/CopyButton.vue";
import {copyNumber, showTooltip} from "../../../../utils/copy";
import {speedDial} from "../../../../utils/softphone";
import {formatNumber} from "../../../../utils/phoneNumbers";

export default {
    name: "PhonebooksPhoneNumberCell",
    components: {CopyButton, BaseTooltip},
    props: {
        number: {
            type: String
        },
        isHover: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            copyButton: {
                'isCopiedName': false,
                'isCopiedNumber': false
            }
        }
    },
    computed: {
        formattedNumber() {
            return formatNumber(this.number)
        }
    },
    methods: {
        copyNumber,
        showTooltip,
        speedDial
    }
}
</script>

<style scoped>

</style>