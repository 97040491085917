import Vue from 'vue'
import Router from 'vue-router'
import store from '../store';
import Portal from "../components/Portal.vue";
import Login from "../components/login/Login.vue";
import ForgotPassword from "../components/login/ForgotPassword.vue";
import IntegrationsLogin from "../components/login/IntegrationsLogin.vue";
import ResetPassword from "../components/login/ResetPassword.vue";
import IntegrationsActivate from "../components/integrations/integrationsActivate.vue";
import CallParkingWallboard from "../components/callParkingWallboard/CallParkingWallboard.vue";

const DEFAULT_PAGE_TITLE = "Webphone"

Vue.use(Router)

let router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Portal,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/forgot-password',
            name: 'forgotPassword',
            component: ForgotPassword,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/user-integrations',
            name: 'userIntegrations',
            component: IntegrationsLogin,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/reset-password',
            name: 'resetPassword',
            component: ResetPassword,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/success-activate',
            name: 'integrationsActivateSuccess',
            component: IntegrationsActivate,
            props: {
                success: true
            },
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/failed-activate',
            name: 'integrationsActivateFailed',
            component: IntegrationsActivate,
            props: {
                success: false
            },
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/parking-wallboard',
            name: 'callParkingWallboard',
            component: CallParkingWallboard,
            meta: {
                requiresAuth: true,
                title: 'Call parking wallboard'
            }
        }
    ],

});

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth) && !store.getters['isLoading']) {
        if (store.getters['user/isLoggedIn']) {
            next();
            return;
        }
        next('/login');
    } else if (to.name === 'login' && store.getters['user/isLoggedIn']) {
        next('/');
    } else {
        next();
    }
});

router.afterEach((to, from) => {
    // https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_PAGE_TITLE
    })
})

export default router;