<template>
    <div class="chip" :class="classes" :style="styles">
        <slot name="prepend">

        </slot>

        <span v-if="$te(text)" class="text">{{ $t(text) }}</span>
        <span v-else class="text">{{ text }}</span>

        <slot name="append">

        </slot>
    </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";

const CHIP_SIZE_BIG = 'big' // height 40px
const CHIP_SIZE_DEFAULT = 'default' // height 28px
const CHIP_SIZE_SMALL = 'small' // height 20px

const CHIP_COLOR_LIGHT_BLUE = 'light-blue' // text color - blue, background color - light blue
const CHIP_COLOR_LIGHT_GREEN = 'light-green' // text color - green, background color - light green
const CHIP_COLOR_GREEN = 'green' // text color - white, background color - green
const CHIP_COLOR_WHITE = 'white' // text color - gray, background color - white
const CHIP_COLOR_GRAY = 'gray' // text color - white, background color - gray
const CHIP_COLOR_BORDER_GRAY = 'border-gray' // text color - dark gray, background color - border gray
const CHIP_COLOR_LIGHT_ORANGE = 'light-orange' // text color - orange, background color - light orange
const CHIP_COLOR_LIGHT_RED = 'light-red' // text color - red, background color - light red
const CHIP_COLOR_LIGHT_GRAY = 'light-gray' // text color - red, background color - light red

const ALIGN_LEFT = "left"
const ALIGN_CENTER = "center"
const ALIGN_RIGHT = "right"

export default {
    name: "Chip",
    components: {BaseButton},
    props: {
        text: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: CHIP_SIZE_DEFAULT
        },
        color: {
            type: String,
            default: ''
        },
        radius: {
            type: String,
            default: ''
        },
        disableBorders: {
            type: Boolean,
            default: false
        },
        width: {
            type: [String, Number],
            default: ""
        },
        align: {
            type: [String],
            default: ALIGN_LEFT
        }
    },
    computed: {
        classes() {
            return {
                'chip-size-big': this.size === CHIP_SIZE_BIG,
                'chip-size-default': this.size === CHIP_SIZE_DEFAULT,
                'chip-size-small': this.size === CHIP_SIZE_SMALL,

                'chip-color-light-blue': this.color === CHIP_COLOR_LIGHT_BLUE,
                'chip-color-light-green': this.color === CHIP_COLOR_LIGHT_GREEN,
                'chip-color-white': this.color === CHIP_COLOR_WHITE,
                'chip-color-green': this.color === CHIP_COLOR_GREEN,
                'chip-color-gray': this.color === CHIP_COLOR_GRAY,
                'chip-color-border-gray': this.color === CHIP_COLOR_BORDER_GRAY,
                'chip-color-light-orange': this.color === CHIP_COLOR_LIGHT_ORANGE,
                'chip-color-light-red': this.color === CHIP_COLOR_LIGHT_RED,
                'chip-light-gray': this.color === CHIP_COLOR_LIGHT_GRAY,

                'chip-disable-borders': this.disableBorders,

                'chip-align-left': this.align === ALIGN_LEFT,
                'chip-align-center': this.align === ALIGN_CENTER,
                'chip-align-right': this.align === ALIGN_RIGHT,
            }
        },
        styles() {
            return {
                borderRadius: this.radius,
                width: (typeof this.width === "number") ? `${this.width}px` : this.width,
            }
        },
    },
    methods: {
        closeHandler() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>

</style>