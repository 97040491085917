const TABS = Object.freeze({
    KEYPAD_TAB: {
        name: "keypad_tab",
        text: "top-bar.menu.softphone",
        icon: "icon-dialpad"
    },
    CONTACTS_TAB: {
        name: "contacts_tab",
        text: "top-bar.menu.phonebooks",
        icon: "icon-stories"
    },
    HISTORY_TAB: {
        name: "history_tab",
        text: "top-bar.menu.history",
        icon: "icon-history"
    },
    CALL_PARKING_TAB: {
        name: "call_parking_tab",
        text: "panel.services.call-parking.title",
        icon: "icon-local-parking"
    }
})

const USER_DEFAULT_MODE_TO_TAB_NAME_MAPPER = Object.freeze({
    "history": TABS.HISTORY_TAB.name,
    "phonebooks": TABS.CONTACTS_TAB.name,
    "dialpad": TABS.KEYPAD_TAB.name,
    "call_parking": TABS.CALL_PARKING_TAB.name
})

/**
 * @param {string} mode
 */
const getTabNameByUserDefaultMode = (mode) => {
    if (mode && USER_DEFAULT_MODE_TO_TAB_NAME_MAPPER.hasOwnProperty(mode)) {
        return USER_DEFAULT_MODE_TO_TAB_NAME_MAPPER[mode]
    }

    return TABS.KEYPAD_TAB.name
}

export {TABS, getTabNameByUserDefaultMode}