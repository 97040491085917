<template>
    <div class="contact-speed-dial-list-item">
        <contact
            :contact="item"
            color="dark_color"
        ></contact>
    </div>
</template>

<script>
import Contact from "../../../../../defaults/contact/Contact.vue";
import breakpoints from "../../../../../../plugins/breakpoints";

export default {
    name: "ContactSpeedDialListItem",
    components: {Contact},
    props: {
        item: {
            type: Object
        }
    },
    data() {
        return {
            breakpoints
        }
    }
}
</script>

<style scoped>

</style>