<template>
    <settings-tab
        @clickSave="save"
        :btn-loading="loading"
        :btn-disabled="!somethingHasBeenChanged"
    >
        <template v-slot:content>
            <div style="display: flex; flex-direction: column; row-gap: 18px;">
                <base-select
                    v-model="dataUser.defaultPhonebookViewId"
                    :options="phonebooksOptions"
                    :dropdown-max-height="340"
                    label="panel.settings.settings-tabs.defaults.default-phonebook-view-label"
                ></base-select>

                <base-select
                    v-model="dataUser.defaultPage"
                    :options="pageOptions"
                    label="panel.settings.settings-tabs.defaults.default-page-label"
                ></base-select>

                <base-select
                    v-model="dataUser.defaultServicesPage"
                    :options="servicesPageOptions"
                    label="panel.settings.settings-tabs.defaults.default-services-page-label"
                ></base-select>

                <base-select
                    v-model="dataUser.defaultAddCallMode"
                    :options="addCallModeOptions"
                    label="panel.settings.settings-tabs.defaults.default-add-call-mode"
                ></base-select>

                <base-select
                    v-model="dataUser.defaultTransferMode"
                    :options="transferModeOptions"
                    label="panel.settings.settings-tabs.defaults.default-transfer-mode"
                ></base-select>
            </div>
        </template>
    </settings-tab>
</template>

<script>
import SettingsTab from "./SettingsTab.vue";
import BaseSelect from "../../defaults/BaseSelect.vue";
import phonebooksSelectOptionsMixin from "../../../mixins/phonebooks/phonebooksSelectOptionsMixin";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "SettingsTabDefaults",
    components: {BaseSelect, SettingsTab},
    mixins: [phonebooksSelectOptionsMixin],
    data() {
        return {
            dataUser: {},
            loading: false
        }
    },
    created() {
        this.dataUser = Object.assign({}, this.user)
    },
    computed: {
        ...mapGetters('user', ['user']),

        pageOptions() {
            return [
                {name: this.$t("top-bar.menu.softphone"), value: "dialpad"},
                {name: this.$t("top-bar.menu.phonebooks"), value: "phonebooks"},
                {name: this.$t("top-bar.menu.sms"), value: "sms"},
                {name: this.$t("top-bar.menu.history"), value: "history"},
                {name: this.$t("top-bar.menu.services"), value: "services"}
            ]

        },

        servicesPageOptions() {
            return [
                {name: this.$t("panel.services.queue-membership.title"), value: "queue_membership"},
                {name: this.$t("panel.services.call-parking.title"), value: "call_parking"},
                {name: this.$t("panel.services.voicemail.title"), value: "voicemail"}
            ]
        },

        addCallModeOptions() {
            return [
                {name: this.$t("top-bar.menu.softphone"), value: "dialpad"},
                {name: this.$t("top-bar.menu.phonebooks"), value: "phonebooks"},
                {name: this.$t("top-bar.menu.history"), value: "history"}
            ]
        },

        transferModeOptions() {
            return [
                {name: this.$t("top-bar.menu.softphone"), value: "dialpad"},
                {name: this.$t("top-bar.menu.phonebooks"), value: "phonebooks"},
                {name: this.$t("top-bar.menu.history"), value: "history"},
                {name: this.$t("panel.services.call-parking.title"), value: "call_parking"}
            ]
        },

        somethingHasBeenChanged() {
            return this.dataUser.languageCode != this.user.languageCode ||
                this.dataUser.defaultPhonebookViewId != this.user.defaultPhonebookViewId ||
                this.dataUser.defaultServicesPage != this.user.defaultServicesPage ||
                this.dataUser.defaultPage != this.user.defaultPage ||
                this.dataUser.defaultAddCallMode != this.user.defaultAddCallMode ||
                this.dataUser.defaultTransferMode != this.user.defaultTransferMode
        },
    },
    methods: {
        ...mapActions(['createAlertMessage']),
        ...mapActions('user', [
            'changeDefaultPhonebookView',
            'changeDefaultServicesPage',
            'changeDefaultPage',
            'changeDefaultAddCallMode',
            'changeDefaultTransferMode'
        ]),

        save() {
            const user = this.user
            let requests = []

            if (this.dataUser.defaultPhonebookViewId != user.defaultPhonebookViewId) {
                requests.push(this.changeDefaultPhonebookView(this.dataUser.defaultPhonebookViewId))
            }

            if (this.dataUser.defaultServicesPage != user.defaultServicesPage) {
                requests.push(this.changeDefaultServicesPage(this.dataUser.defaultServicesPage))
            }

            if (this.dataUser.defaultPage != user.defaultPage) {
                requests.push(this.changeDefaultPage(this.dataUser.defaultPage))
            }

            if (this.dataUser.defaultAddCallMode != user.defaultAddCallMode) {
                requests.push(this.changeDefaultAddCallMode(this.dataUser.defaultAddCallMode))
            }

            if (this.dataUser.defaultTransferMode != user.defaultTransferMode) {
                requests.push(this.changeDefaultTransferMode(this.dataUser.defaultTransferMode))
            }

            if (requests.length > 0) {
                this.loading = true
            }

            Promise.all(requests)
                .then(() => this.createAlertMessage({type: 'success', message: i18n.t('panel.settings.changes-saved-successfully')}))
                .catch(() => {})
                .finally(() => this.loading = false)
        },
    }
}
</script>

<style scoped>

</style>