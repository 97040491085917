<template>
    <div>
        <base-tooltip
            :text="hint"
            :width="140"
            position="bottom"
            text-align="center"
            style="width: 100%"
        >
            <template v-slot:activator>
                <contact-info
                    :primary-info="primaryInfo"
                    :secondary-info="secondaryInfo"
                    :is-hover="isHover"
                    :copy-value="copyValue"
                ></contact-info>
            </template>
        </base-tooltip>
    </div>
</template>

<script>
import CopyButton from "../../../defaults/CopyButton.vue";
import BaseTooltip from "../../../defaults/BaseTooltip.vue";
import {formatNumber, getPhoneNumberDigitsOnly} from "../../../../utils/phoneNumbers";
import CallHistory from "../../../../models/CallHistory";
import ContactInfo from "../../../defaults/contact/ContactInfo.vue";

export default {
    name: "CallHistoryCalleridCell",
    components: {ContactInfo, BaseTooltip, CopyButton},
    props: {
        item: {
            type: CallHistory
        },
        isHover: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            copyButton: {
                'isCopiedName': false,
                'isCopiedNumber': false
            },
        }
    },
    computed: {
        hint() {
            if (this.item.isOutgoing()) {
                return this.$t("panel.call-history.caller-id-used")
            }

            return this.$t("panel.call-history.call-received-on")
        },

        formattedCallerid() {
            return formatNumber(this.item.callerid)
        },

        primaryInfo() {
            return this.item.calleridName || this.formattedCallerid
        },

        secondaryInfo() {
            if (!this.item.calleridName) {
                return this.item.calleridPrefix || ''
            }

            return this.item.calleridPrefix ? `${this.item.calleridPrefix} · ${this.formattedCallerid}` : this.formattedCallerid;
        },

        copyValue() {
            if (this.item.calleridName) {
                return `${this.item.calleridName} ${getPhoneNumberDigitsOnly(this.item.callerid)}`
            }

            return getPhoneNumberDigitsOnly(this.item.callerid);
        }
    }
}
</script>

<style scoped>

</style>