<template>
    <div class="login-footer">
        <base-select
            @input="changeLocale($event)"
            :value="$i18n.locale"
            :options="languages"
            position="top"
            style="max-width: 160px"
        ></base-select>

        <base-button
            v-if="isElectronExist()"
            @click="reloadPage"
            size="big"
            text="top-bar.reload-button"
            icon="icon-refresh"
            class="reload-button"
        ></base-button>

        <electron-app-version
            v-if="isElectronExist() && electronAppVersion"
            :style="breakpoints.isMobile ? 'flex-basis: 100%; text-align: center' : ''"
        ></electron-app-version>

        <apps-dropdown
            v-if="apps.length && !isElectronExist()"
            v-model="showDownloadAppDropdown"
            :width="172"
            :style="breakpoints.isMobile ? '' : 'margin-left: auto'"
            position="top"
            class="download-app-dropdown"
        >
            <template v-slot:activator>
                <download-app-activator :active="showDownloadAppDropdown"></download-app-activator>
            </template>
        </apps-dropdown>
    </div>
</template>

<script>
import BaseButton from "../../defaults/BaseButton.vue";
import AppsDropdown from "../../defaults/dropdowns/AppsDropdown.vue";
import {changeLocale} from "../../../locales";
import {isElectronExist} from "../../../utils/electron";
import {mapGetters} from "vuex";
import {reloadPage} from "../../../utils/DOM";
import DownloadAppActivator from "./DownloadAppActivator.vue";
import ElectronAppVersion from "../../defaults/ElectronAppVersion.vue";
import BaseSelect from "../../defaults/BaseSelect.vue";
import breakpoints from "../../../plugins/breakpoints";

export default {
    name: "LoginFooter",
    components: {BaseSelect, ElectronAppVersion, DownloadAppActivator, AppsDropdown, BaseButton},
    data() {
        return {
            showDownloadAppDropdown: false,
            breakpoints
        }
    },
    computed: {
        ...mapGetters(["electronAppVersion", "languages"]),
        ...mapGetters('apps', ['apps'])
    },
    methods: {
        isElectronExist,
        reloadPage,
        changeLocale
    }
}
</script>

<style scoped>

</style>