<template>
    <label class="base-checkbox" :class="classes">
        <div :style="circle ? 'border-radius: 50%' : ''" class="base-checkbox-custom">
            <span v-if="value" class="icon icon-done"></span>
            <input
                @input="onInput"
                @change="onChange"
                :checked="value"
                :disabled="disabled"
                type="checkbox"
            >
        </div>

        <span v-if="label" class="base-checkbox-label">
            {{ $te(label) ? $t(label) : label }}
        </span>
    </label>
</template>

<script>
const COLOR_BLUE = "blue"
const COLOR_GREEN = "green"

export default {
    name: "BaseCheckbox",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        semiSelected: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            default: COLOR_BLUE
        },
        circle: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        classes() {
            return {
                "base-checkbox-selected": this.value,
                "base-checkbox-disabled": this.disabled,
                "base-checkbox-semi-selected": this.semiSelected && !this.value && !this.disabled,

                "base-checkbox-color-blue": this.color === COLOR_BLUE,
                "base-checkbox-color-green": this.color === COLOR_GREEN,
            }
        }
    },
    methods: {
        onInput() {
            if (!this.editable) return

            this.$emit("input", !this.value)
        },
        onChange() {
            if (!this.editable) return

            this.$emit('change')
        }
    }
}
</script>

<style scoped>

</style>