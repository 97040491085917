import store from "../store"
import {numbersAreMatched} from "../utils/phoneNumbers";

export const CALL_TYPES = Object.freeze({
    OUTGOING: "outgoing",
    INCOMING: "incoming",
    MISSED: "missed"
})

export const CALL_TYPE_TEXT_MAPPER = Object.freeze({
    outgoing: "panel.call-history.call-types.outgoing",
    incoming: "panel.call-history.call-types.incoming",
    missed: "panel.call-history.call-types.missed"
})

export default class CallHistory {
    /**
     * @param {number} id
     * @param {string} callId
     * @param {string} callerid
     * @param {string|null} calleridPrefix
     * @param {string} date
     * @param {number} duration
     * @param {boolean} isCallRecordingsExist
     * @param {string} name
     * @param {string} number
     * @param {string} type
     */
    constructor({
        id,
        callId,
        callerid,
        calleridPrefix,
        date,
        duration,
        isCallRecordingsExist,
        name,
        number,
        type
    }) {
        this._id = id
        this._callId = callId
        this._callerid = callerid
        this._callerIdPrefix = calleridPrefix
        this._date = date
        this._duration = duration
        this._isCallRecordingsExist = isCallRecordingsExist
        this._name = name
        this._number = number
        this._type = type
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get callId() {
        return this._callId;
    }

    set callId(value) {
        this._callId = value;
    }

    get callerid() {
        return this._callerid;
    }

    set callerid(value) {
        this._callerid = value;
    }

    get calleridPrefix() {
        return this._callerIdPrefix;
    }

    set calleridPrefix(value) {
        this._callerIdPrefix = value;
    }

    get date() {
        return this._date;
    }

    set date(value) {
        this._date = value;
    }

    get duration() {
        return this._duration;
    }

    set duration(value) {
        this._duration = value;
    }

    get isCallRecordingsExist() {
        return this._isCallRecordingsExist;
    }

    set isCallRecordingsExist(value) {
        this._isCallRecordingsExist = value;
    }

    get name() {
        return this._name;
    }

    set name(value) {
        this._name = value;
    }

    get number() {
        return this._number;
    }

    set number(value) {
        this._number = value;
    }

    get type() {
        return this._type;
    }

    set type(value) {
        this._type = value;
    }

    get typeText() {
        return CALL_TYPE_TEXT_MAPPER[this.type]
    }

    /**
     * @return {null|string}
     */
    get calleridName() {
        // try to find contact in phonebooks
        const contact = store.getters["details/getContactByNumber"](this.callerid)
        if (contact.id) {
            return contact.name
        }

        // try to find a known callerID
        const callerid = store.getters["user/callerIds"].find((v) => numbersAreMatched(v.num, this.callerid))
        if (callerid) {
            return callerid.name
        }

        return null
    }

    isOutgoing() {
        return this.type === CALL_TYPES.OUTGOING
    }

    isIncoming() {
        return this.type === CALL_TYPES.INCOMING
    }

    isMissed() {
        return this.type === CALL_TYPES.MISSED
    }

    isAnonymous() {
        return this.number === 'anonymous'
    }
}