<template>
    <base-button
        @click="onClick"
        :disabled="disabled"
        :loading="loading"
        :style="style"
        :text="text"
        size="big"
        color="blue"
        radius="18px"
    ></base-button>
</template>

<script>
import BaseButton from "../../defaults/BaseButton.vue";

export default {
    name: "AuthButton",
    components: {BaseButton},
    props: {
        text: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        width: {
            type: [String, Number],
            default: 155
        }
    },
    computed: {
        style() {
            return {
                width: (typeof this.width === "number") ? `${this.width}px` : this.width,
                fontSize: "14px"
            }
        }
    },
    methods: {
        onClick() {
            this.$emit("click")
        }
    }
}
</script>

<style scoped>

</style>