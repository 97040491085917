<template>
    <active-call-keypad-tab class="active-call-keypad-dial-pad-tab">
        <dial-pad
            @input="onInput"
            :value="value"
            v-bind="dialPadSize"
            placeholder="softphone-dial-pad.placeholder"
            color="color_dark"
            autofocus
        ></dial-pad>
    </active-call-keypad-tab>
</template>

<script>
import DialPad from "../../../../dialPad/DialPad.vue";
import ActiveCallKeypadTab from "../ActiveCallKeypadTab.vue";
import callDialPadMixin from "../../../../../../mixins/softphone/callDialPadMixin";

export default {
    name: "DialPadTab",
    components: {ActiveCallKeypadTab, DialPad},
    mixins: [callDialPadMixin],
    props: {
        value: {
            type: String,
            default: ""
        }
    },
    methods: {
        onInput(number) {
            this.$emit("input", number)
        }
    }
}
</script>

<style scoped>

</style>