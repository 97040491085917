<template>
    <tr class="phonebooks-table-row" @mouseover="isHover = true" @mouseleave="isHover = false">
        <td class="phonebooks-table-row-cell">
            <contact :contact="item">
                <template v-slot:info>
                    <contact-info
                        :primary-info="item.name"
                        :secondary-info="selectedPhonebookId === 'all' ? phonebookName : ''"
                        :is-hover="isHover"
                        :copy-value="item.name"
                    ></contact-info>
                </template>
            </contact>
        </td>

        <td class="phonebooks-table-row-cell">
            <phonebooks-phone-number-cell
                :is-hover="isHover"
                :number="item.number"
            ></phonebooks-phone-number-cell>
        </td>

        <td class="phonebooks-table-row-cell">
            <phonebooks-actions-cell
                v-if="!breakpoints.isTabletOrMobile"
                :item="item"
                :is-expanded="isExpanded"
                @click-integrations="onExpandClick"
            ></phonebooks-actions-cell>

            <base-dropdown
                v-else-if="breakpoints.isTabletOrMobile && (canShowIntegrations || !isReadOnlyPhonebook)"
                :width="canShowIntegrations ? 114 : 76"
                align="right"
            >
                <template v-slot:activator>
                    <base-button
                        size="medium"
                        color="table"
                        icon="icon-more"
                        class="entry-more-button"
                        style="margin-left: auto"
                    ></base-button>
                </template>

                <template v-slot:no-scrollable-content>
                    <phonebooks-actions-cell
                        :item="item"
                        :is-expanded="isExpanded"
                        @click-integrations="onExpandClick"
                    ></phonebooks-actions-cell>
                </template>
            </base-dropdown>
        </td>
    </tr>
</template>

<script>
import BaseButton from "../../defaults/BaseButton.vue";
import Contact from "../../defaults/contact/Contact.vue";
import PhonebooksPhoneNumberCell from "./cells/PhonebooksPhoneNumberCell.vue";
import PhonebooksActionsCell from "./cells/PhonebooksActionsCell.vue";
import BaseDropdown from "../../defaults/BaseDropdown.vue";
import {canShowIntegrations} from "../../../utils/integrations";
import {mapGetters} from "vuex";
import breakpoints from "../../../plugins/breakpoints";
import ContactInfo from "../../defaults/contact/ContactInfo.vue";

export default {
    name: "PhonebooksTableRow",
    components: {
        ContactInfo,
        BaseDropdown,
        PhonebooksActionsCell,
        PhonebooksPhoneNumberCell,
        Contact,
        BaseButton
    },
    props: {
        item: {
            type: Object
        },
        isExpanded: {
            type: Boolean,
            default: false
        },
        selectedPhonebookId: {
            type: [Number, String],
            default: "all"
        }
    },
    data() {
        return {
            isHover: false,
            breakpoints
        }
    },
    computed: {
        ...mapGetters("details", ["getIsReadOnlyPhonebook", "phonebooks"]),

        phonebookName() {
            let phonebook = this.phonebooks.find(phonebook => phonebook.id === this.item.phonebook_id)

            if (phonebook) {
                return phonebook.name
            }

            return ''
        },

        isReadOnlyPhonebook() {
            return this.getIsReadOnlyPhonebook(this.item.phonebook_id)
        },

        canShowIntegrations() {
            return canShowIntegrations(this.item.number)
        }
    },
    methods: {
        onExpandClick() {
            this.$emit("expand")
        }
    }
}
</script>

<style scoped>

</style>