import SocketIOHelper from "../helpers/SocketIOHelper";
import {localStorageHelper} from "../helpers/LocalStorageHelper";
import {sendToElectron} from "./electron";
import store from '../store';
import router from '../router';

const initialPath = `${window.location.hash}`.replaceAll("#", "")

/**
 * Function helps to logout the user after a /logout request has been sent
 */
const authLogOut = () => {
    if (store.getters["user/isLoggedIn"]) {
        SocketIOHelper.disconnect()
        localStorageHelper.clear()
        sendToElectron("clearCookie")
        store.dispatch("user/resetState")
        router.push('/login')
    }
}

/**
 * Function helps to login the user after all login requests have been successfully processed
 */
const authLogIn = () => {
    localStorageHelper.clear()
    store.dispatch("user/changeIsLogged", true)
    sendToElectron("login")

    const initialRoute = router.resolve({
        path: initialPath
    })
    if (initialRoute && initialRoute?.route?.meta?.requiresAuth) {
        router.push(initialPath)
    } else {
        router.push("/")
    }
}

export { authLogOut, authLogIn }