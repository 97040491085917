<template>
    <div class="base-table pos-relative" :class="classes">
        <base-spinner v-if="loading" center></base-spinner>

        <div v-else-if="!_items.length && !(noItemsText === false)" class="base-table-empty pos-absolute-center">
            {{ noItemsText ? ($te(noItemsText) ? $t(noItemsText) : noItemsText) : "No data" }}
        </div>

        <template v-else>
            <base-table-header
                @select="isFullSelected ? fullDeselect() : fullSelect()"
                @update:sort="onUpdateSort"
                :headers="_headers"
                :selectable="selectable"
                :multiple="isMultipleSelect"
                :selected="isFullSelected"
                :semi-selected="isSemiSelected"
                :width-columns="widthColumns"
            ></base-table-header>

            <!-- tag <transition> added to fix a bug related to vuebar -->
            <transition>
                <div class="base-table-content" v-bar>
                    <div class="base-table-content-scroll">
                        <table>
                            <colgroup>
                                <col
                                    v-for="(widthColumn, idx) in widthColumns"
                                    :width="widthColumn"
                                    :key="idx"
                                >
                            </colgroup>

                            <tbody>
                                <template v-for="(item, idx) in _items" >
                                    <slot :item="item" :toggleRow="toggleRow" :isExpanded="expandedRowKeys.includes(item[itemKey])" name="row">
                                        <base-table-row
                                            @select="isSelectedRow(item[itemKey]) ? deselectRow(item) : selectRow(item)"
                                            :columns="columns[idx]"
                                            :selectable="selectable"
                                            :selected="isSelectedRow(item[itemKey])"
                                        ></base-table-row>
                                    </slot>

                                    <tr v-if="expandableRows && expandedRowKeys.includes(item[itemKey])" class="base-table-expanded-row">
                                        <td :colspan="expandedRowColspan">
                                            <slot :item="item" :toggleRow="toggleRow" name="expandedRow"></slot>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </transition>
        </template>
    </div>
</template>

<script>
import BaseSpinner from "../BaseSpinner.vue";
import BaseTableRow from "./BaseTableRow.vue";
import BaseTableHeader from "./BaseTableHeader.vue";
import TableHeader, {TABLE_HEADER_SORT} from "../../../models/baseTable/TableHeader";

export default {
    name: "BaseTable",
    components: {BaseTableHeader, BaseTableRow, BaseSpinner},
    props: {
        value: {
            type: [Array, Object],
            default: null
        },
        selectable: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        headers: {
            type: Array,
            default() {
                return []
            }
        },
        items: {
            type: Array,
            default() {
                return []
            }
        },
        loading: {
            type: Boolean,
            default: false
        },
        expandableRows: {
            type: Boolean,
            default: false
        },
        itemKey: {
            type: String,
            default: "id"
        },
        rounded: {
            type: Boolean,
            default: false
        },
        showExpandedRowsByDefault: {
            type: Boolean,
            default: false
        },
        noItemsText: {
            type: [String, Boolean],
            default: ""
        }
    },
    data() {
        return {
            expandedRowKeys: [],
            sortedHeaders: []
        }
    },
    created() {
        if (this.showExpandedRowsByDefault) {
            this.expandAllRows()
        }
    },
    watch: {
        showExpandedRowsByDefault(value) {
            if (value) {
                this.expandAllRows()
            } else {
                this.collapseAllRows()
            }
        },
        _items() {
            if (this.showExpandedRowsByDefault) {
                this.expandAllRows()
            }
        }
    },
    computed: {
        classes() {
            return {
                "base-table-rounded": this.rounded
            }
        },
        isSemiSelected() {
            return (this.value instanceof Array) && this.value.length !== this._items.length && this.value.length > 0
        },
        isFullSelected() {
            return (this.value instanceof Array) && this.value.length === this._items.length && this._items.length > 0
        },
        isMultipleSelect() {
            return this.selectable && this.multiple && this.value instanceof Array
        },
        _headers() {
            if (this.sortedHeaders.length && this.sortedHeaders.length === this.headers.length) {
                return this.sortedHeaders.map((v) => new TableHeader(v))
            }

            return this.headers.map((v) => new TableHeader(v))
        },
        _items() {
            if (this.sortedHeaders.length && this.sortedHeaders.length === this.headers.length) {
                return this.localSort(this.items, this.sortedHeaders)
            }

            return this.items
        },
        columns() {
            return this._items.map(item => {
                return this._headers.map(header => {
                    return {
                        value: item[header.key],
                        class: header.className
                    }
                })
            })
        },
        widthColumns() {
            const weight = this._headers.reduce((prev, current) => typeof current.fr === 'number' ? prev + current.fr : prev, 0)
            const result = this._headers.map(v => typeof v.fr === 'number' ? `${v.fr / weight * 100}%` : v.fr)

            if (this.selectable) {
                result.unshift("52px") // fixed width for checkbox
            }

            return result
        },
        expandedRowColspan() {
            let result = this._headers.length

            if (this.selectable) {
                result += 1
            }

            return result
        }
    },
    methods: {
        fullSelect() {
            if (!this.isMultipleSelect) {
                return
            }

            this.$emit("input", this._items)
        },
        fullDeselect() {
            if (!this.isMultipleSelect) {
                return
            }

            this.$emit("input", [])
        },
        selectRow(item) {
            if (!this.selectable) {
                return
            }

            let newValue
            if (this.isMultipleSelect) {
                newValue = [...this.value]
                newValue.push(item)
            } else {
                newValue = item
            }

            this.$emit("input", newValue)
        },
        deselectRow(item) {
            if (!this.selectable) {
                return
            }

            const newValue = this.isMultipleSelect ? this.value.filter((i) => i[this.itemKey] !== item[this.itemKey]) : {}
            this.$emit("input", newValue)
        },
        isSelectedRow(key) {
            if (!this.selectable) {
                return
            }

            if (this.isMultipleSelect) {
                return Boolean(this.value.find((item) => item[this.itemKey] === key))
            }

           return this.value[this.itemKey] === key
        },
        toggleRow(key) {
            if (this.expandedRowKeys.includes(key)) {
                this.expandedRowKeys = this.expandedRowKeys.filter(rowKey => rowKey !== key)
            } else {
                this.expandedRowKeys.push(key)
            }
        },
        expandAllRows() {
            this.expandedRowKeys = this._items.map((item) => item[this.itemKey])
        },
        collapseAllRows() {
            this.expandedRowKeys = []
        },
        onUpdateSort(newHeaders) {
            this.sortedHeaders = newHeaders

            this.$emit("update:sort", newHeaders)
        },
        /**
         * @param {array} items
         * @param {TableHeader[]} headers
         */
        localSort(items, headers) {
            const sortByHeader = headers.find((v) => v.sort !== TABLE_HEADER_SORT.DEFAULT)
            if (!sortByHeader) {
                return items
            }

            const sortProperty = sortByHeader.key
            const sortDirection = sortByHeader.sort

            return items.map(v => v).sort((a, b) => {
                const targetA = a[sortProperty]
                const targetB = b[sortProperty]

                if (typeof targetA === 'string' && typeof targetB === 'string') {
                    const targetATransformed = targetA.toLowerCase()
                    const targetBTransformed = targetB.toLowerCase()
                    return sortDirection === TABLE_HEADER_SORT.ASC ? targetATransformed.localeCompare(targetBTransformed) :
                        (-1) * targetATransformed.localeCompare(targetBTransformed)
                }

                if (typeof targetA === 'number' && typeof targetB === 'number') {
                    return sortDirection === TABLE_HEADER_SORT.ASC ? targetA - targetB : targetB - targetA
                }

                return 0
            })
        }
    }
}
</script>

<style scoped>

</style>