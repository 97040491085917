<template>
    <div class="tab settings-tab" :class="{'active': this.active}">
        <div class="settings-wrapper" v-bar>
            <div>
                <div class="settings-container">
                    <div v-show="menuIsShown" class="settings-menu-wrapper">
                        <div class="settings-menu">
                            <settings-menu-item
                                @click="showTab(GENERAL_TAB)"
                                :is-active="isActiveTab(GENERAL_TAB)"
                                :name="'panel.settings.settings-tabs.general'"
                                :icon-class="'icon-settings'"
                            ></settings-menu-item>

                            <settings-menu-item
                                @click="showTab(USER_TAB)"
                                :is-active="isActiveTab(USER_TAB)"
                                :name="'panel.settings.settings-tabs.user'"
                                :icon-class="'icon-person'"
                            ></settings-menu-item>

                            <settings-menu-item
                                @click="showTab(DEFAULTS_TAB)"
                                :is-active="isActiveTab(DEFAULTS_TAB)"
                                :name="'panel.settings.settings-tabs.defaults.title'"
                                :icon-class="'icon-fact-check'"
                            ></settings-menu-item>

                            <settings-menu-item
                                v-if="isPossibleToChangeIODevices"
                                @click="showTab(SOUND_TAB)"
                                :is-active="isActiveTab(SOUND_TAB)"
                                :name="'panel.settings.settings-tabs.sound.title'"
                                :icon-class="'icon-volume-up'"
                            ></settings-menu-item>

                            <settings-menu-item
                                v-if="isTestFeaturesEnabled"
                                @click="showTab(DEVICE_MANAGEMENT_TAB)"
                                :is-active="isActiveTab(DEVICE_MANAGEMENT_TAB)"
                                :name="'panel.settings.settings-tabs.device-management.title'"
                                :icon-class="'icon-devices'"
                            ></settings-menu-item>

                            <settings-menu-item
                                @click="showTab(INTEGRATIONS_TAB)"
                                :is-active="isActiveTab(INTEGRATIONS_TAB)"
                                :name="'panel.settings.settings-tabs.crm-integrations.menu-title'"
                                :icon-class="'icon-join-right-black'"
                            ></settings-menu-item>
                        </div>

                        <electron-app-version
                            v-if="isElectronExist && electronAppVersion"
                            style="margin-top: 12px"
                        />
                    </div>

                    <div v-show="tabViewIsShown" class="settings-tab-view">
                        <settings-tab-general v-show="isActiveTab(GENERAL_TAB)"></settings-tab-general>
                        <settings-tab-user v-show="isActiveTab(USER_TAB)"></settings-tab-user>
                        <settings-tab-integrations v-show="isActiveTab(INTEGRATIONS_TAB)"></settings-tab-integrations>
                        <settings-tab-sound v-if="isPossibleToChangeIODevices" v-show="isActiveTab(SOUND_TAB)"></settings-tab-sound>
                        <settings-tab-device-management v-if="isTestFeaturesEnabled" v-show="isActiveTab(DEVICE_MANAGEMENT_TAB)"></settings-tab-device-management>
                        <settings-tab-defaults v-show="isActiveTab(DEFAULTS_TAB)"></settings-tab-defaults>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SettingsMenuItem from "./SettingsMenuItem.vue";
import SettingsTabUser from "./SettingsTabUser.vue";
import SettingsTabGeneral from "./SettingsTabGeneral.vue";
import SettingsTabIntegrations from "./settingsTabIntegrations.vue";
import SettingsTabSound from "./SettingsTabSound.vue";
import SettingsTabDeviceManagement from "./settingsTabDeviceManagement/SettingsTabDeviceManagement.vue";
import {mapActions, mapGetters} from "vuex";
import {isElectronExist} from "../../../utils/electron";
import SettingsTabDefaults from "./SettingsTabDefaults.vue";
import ElectronAppVersion from "../../defaults/ElectronAppVersion.vue";

const USER_TAB = "user_tab"
const GENERAL_TAB = "general_tab"
const INTEGRATIONS_TAB = "integrations_tab"
const SOUND_TAB = "sound_tab"
const DEVICE_MANAGEMENT_TAB = "device_management_tab"
const DEFAULTS_TAB = "defaults_tab"

export default {
    name: "Settings",
    components: {
        ElectronAppVersion,
        SettingsTabDefaults,
        SettingsTabDeviceManagement,
        SettingsTabSound, SettingsTabIntegrations, SettingsTabGeneral, SettingsTabUser, SettingsMenuItem},
    props: {
        active: Boolean,
        user: Object,
        windowWidth: Number
    },
    data() {
        return {
            currentTab: GENERAL_TAB,
            menuIsShown: true,
            tabViewIsShown: true,
            USER_TAB,
            GENERAL_TAB,
            INTEGRATIONS_TAB,
            SOUND_TAB,
            DEVICE_MANAGEMENT_TAB,
            DEFAULTS_TAB
        }
    },
    created() {
        if (this.isMobile) {
            this.mobileSetup()
        }

        this.$root.$on('clickBackToMenu', () => {
            this.showMenu()
        })

        document.addEventListener('showIntegrationsSettingsTab', this.showIntegrationsSettingsTab)
    },
    destroyed() {
        document.removeEventListener('showIntegrationsSettingsTab', this.showIntegrationsSettingsTab)
    },
    computed: {
        ...mapGetters('user', ['isIntegrationsEnabled', 'isTestFeaturesEnabled']),
        ...mapGetters('integrations', ['integrations']),
        ...mapGetters(['electronAppVersion']),

        isMobile() {
            return this.windowWidth <= 960
        },

        isPossibleToChangeIODevices() {
            return "setSinkId" in HTMLMediaElement.prototype
        }
    },
    watch: {
        windowWidth() {
            if (this.isMobile) {
                this.mobileSetup()
            } else {
                this.showTab(USER_TAB)
            }
        }
    },
    methods: {
        ...mapActions(['changeActiveTab']),

        showTab(tabName) {
            if (this.isMobile) {
                this.menuIsShown = false
                this.tabViewIsShown = true
            } else {
                this.menuIsShown = true
                this.tabViewIsShown = true
            }
            this.currentTab = tabName
        },

        showMenu() {
            this.tabViewIsShown = false
            this.menuIsShown = true
            this.currentTab = ""
        },

        isActiveTab(tabName) {
            return this.currentTab === tabName
        },

        mobileSetup() {
            this.currentTab = ""
            this.menuIsShown = true
            this.tabViewIsShown = false
        },

        showIntegrationsSettingsTab() {
            this.changeActiveTab('settings')
            this.showTab(INTEGRATIONS_TAB)
        },

        isElectronExist
    }
}
</script>

<style scoped>

</style>