<template>
    <avatar class="contact-avatar" v-bind="bindToAvatar"></avatar>
</template>

<script>
import Avatar from "../Avatar.vue";
import {isExtension} from "../../../utils/phoneNumbers";

export default {
    name: "ContactAvatar",
    components: {Avatar},
    props: {
        contact: {
            type: Object,
            required: true
        },
        callType: {
            default: "",
            type: String
        }
    },
    computed: {
        bindToAvatar() {
            const result = {}

            result.size = "small"

            if (!this.contact.id) {
                result["unknown-person"] = true
                result.callType = this.callType

                return result
            }

            result.src = this.contact.avatar
            result.text = this.contact.name
            result.color = this.contact.color
            result.showStatus = isExtension(this.contact.number)
            result.status = this.contact.status

            return result
        }
    }
}
</script>

<style scoped>

</style>