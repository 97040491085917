const PARAM_NAME_PAGE = "page"
const PARAM_NAME_ITEMS_PER_PAGE = "items_per_page"

export default class Pagination {
    /**
     * @param {number} page
     * @param {number} limit
     * @param {number} total
     */
    constructor({page, limit, total}) {
        this._page = page
        this._limit = limit
        this._total = total
    }

    /**
     * @return {number}
     */
    get currentPage() {
        return this._page
    }

    /**
     * @return {number}
     */
    get limit() {
        return this._limit
    }

    /**
     * @return {number}
     */
    get total() {
        return this._total
    }

    /**
     * @return {number}
     */
    get totalPages() {
        return Math.ceil(this.total / this.limit)
    }

    /**
     * @param {number} value
     */
    set total(value) {
        this._total = value
    }

    /**
     * @return {number}
     */
    get pageStartOffset() {
        if (this.total <= 0) return 0
        if (this.isFirstPage()) return 1

        return (this.currentPage - 1) * this._limit + 1
    }

    /**
     * @return {number}
     */
    get pageStopOffset() {
        if (this.total <= 0) return 0
        if (this.isLastPage()) return this.total

        return this.currentPage * this._limit
    }

    /**
     * @return {number}
     */
    isLastPage() {
        return this.currentPage >= this.totalPages
    }

    /**
     * @return {number}
     */
    isFirstPage() {
        return this.currentPage <= 1
    }

    toNextPage() {
        if (this.isLastPage()) return

        this._page += 1
    }

    toPrevPage() {
        if (this.isFirstPage()) return

        this._page -= 1
    }

    toPage(page) {
        if (page >= 1 && page <= this.totalPages) {
            this._page = page
        }
    }

    /**
     * @return {{}}
     */
    get requestParams() {
        let result = {}

        result[PARAM_NAME_PAGE] = this.currentPage
        result[PARAM_NAME_ITEMS_PER_PAGE] = this.limit

        return result
    }
}