<template>
    <div class="dnd-switcher-container"
         @click="onClick"
         @mouseover="onMouseover"
         @mouseleave="onMouseleave"
         :class="{'dnd-switcher-container-in-dropdown': inDropdown}"
    >
        <base-tooltip :disabled="!getIsRegistered" text="top-bar.dnd-tooltip" position="bottom">
            <template v-slot:activator>
                <div class="dnd-switcher" :class="classes">
                    <d-n-d-switcher-status
                        :color="statusColor"
                        :is-online="user.isOnline"
                        :loading="changeIsOnlineLoading"
                        :is-hover="isHover"
                        size="bg"
                        style="margin-right: 10px;"
                    ></d-n-d-switcher-status>

                    <p>{{ $t("top-bar.status." + (user.isOnline ? 'online' : 'do-not-disturb')) }}</p>
                </div>
            </template>
        </base-tooltip>
    </div>
</template>

<script>
import BaseTooltip from "../../defaults/BaseTooltip.vue";
import BaseSpinner from "../../defaults/BaseSpinner.vue";
import {mapActions, mapGetters} from "vuex";
import DNDSwitcherStatus from "./DNDSwitcherStatus.vue";
import {STATUS_COLOR} from "./config";

export default {
    name: "DNDSwitcher",
    components: {DNDSwitcherStatus, BaseSpinner, BaseTooltip},
    props: {
        inDropdown: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isHover: false
        }
    },
    computed: {
        ...mapGetters("user", ["user", "changeIsOnlineLoading"]),
        ...mapGetters("softphone", ["getIsRegistered"]),

        classes() {
            return {
                "dnd-switcher-online": this.user.isOnline,
                "dnd-switcher-do-not-disturb": !this.user.isOnline,
                "dnd-switcher-disabled": !this.getIsRegistered
            }
        },

        statusColor() {
            if (this.user.isOnline) {
                return STATUS_COLOR.GREEN
            }

            if (this.inDropdown) {
                return STATUS_COLOR.RED
            }

            return STATUS_COLOR.INVERTED_RED
        }
    },
    methods: {
        ...mapActions("user", ["changeIsOnline"]),

        onMouseover() {
            if (!this.getIsRegistered) return

            this.isHover = true
        },

        onMouseleave() {
            if (!this.getIsRegistered) return

            this.isHover = false
        },

        onClick() {
            if (this.changeIsOnlineLoading) return

            this.changeIsOnline(!this.user.isOnline)
        }
    }
}
</script>

<style scoped>

</style>