<template>
    <div class="call-history-pagination">
        <div v-if="_value.total" class="call-history-pagination-page-text">
            {{ $t("panel.call-history.of", {pageStart: _value.pageStartOffset, pageStop: _value.pageStopOffset, itemsLength: _value.total}) }}
        </div>

        <div class="call-history-pagination-buttons">
            <base-button
                @click="prevPage"
                :disabled="_value.isFirstPage()"
                size="big"
                color="gray"
                icon="icon-chevron-left"
            ></base-button>

            <base-button
                @click="nextPage"
                :disabled="_value.isLastPage()"
                size="big"
                color="gray"
                icon="icon-chevron-right"
            ></base-button>

            <base-button
                @click="refresh"
                size="big"
                color="gray"
                icon="icon-refresh"
                ref="refreshBtn"
            ></base-button>
        </div>
    </div>
</template>

<script>
import BaseSelect from "../../defaults/BaseSelect.vue";
import BaseButton from "../../defaults/BaseButton.vue";
import Pagination from "../../../helpers/Pagination";

export default {
    name: "CallHistoryPagination",
    components: {BaseButton, BaseSelect},
    props: {
        value: {
            type: Pagination
        }
    },
    computed: {
        _value() {
            return Object.assign(Object.create(Object.getPrototypeOf(this.value)), this.value)
        }
    },
    methods: {
        update(v) {
            this.$emit('input', v)
        },
        prevPage() {
            const value = this._value
            value.toPrevPage()

            this.update(value)
        },
        nextPage() {
            const value = this._value
            value.toNextPage()

            this.update(value)
        },
        refresh() {
            this.$refs.refreshBtn.$el.blur()
            this.$emit('refresh')
        }
    }
}
</script>

<style scoped>

</style>