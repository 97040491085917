<template>
    <div class="tab history-tab" :class="{'active': this.active}">
        <div v-if="!loading && (callHistory.length || filters.count || localLoading)" class="history-tab-header">
            <!-- Search field -->
            <base-input
                v-model="filters.search"
                @input="activateSearch"
                class="find-by-number"
                type="text"
                placeholder="panel.call-history.search-placeholder"
                icon="icon-search"
                style="max-width: 220px"
            ></base-input>

            <!-- Filters popup toggler -->
            <base-button
                @click="toggleFiltersPopup(true)"
                color="blue"
                size="big"
                icon="icon-filter"
                text="panel.call-history.filters-btn"
                class="btn-filter"
            >
                <template v-slot:append>
                    <span v-if="filters.count" class="counter">{{ filters.count }}</span>
                </template>
            </base-button>

            <!-- Filter chips -->
            <chip
                v-for="(chip, index) of filters.chips"
                v-if="!breakpoints.isSmMobile"
                :key="index"
                :text="chip.value"
                size="big"
                color="light-blue"
            >
                <template v-slot:append>
                    <base-button
                        @click="removeFilter(chip.name)"
                        :size="'small'"
                        :color="'white'"
                        :icon="'icon-clear'"
                        :icon-size="14"
                        :radius="'50%'"
                        class="chip-button-close"
                    ></base-button>
                </template>
            </chip>

            <!-- Pagination -->
            <call-history-pagination
                v-model="pagination"
                @input="updateHistory(false)"
                @refresh="updateHistory(false)"
                style="margin-left: auto"
            ></call-history-pagination>

            <history-filters-popup
                v-if="filtersPopup"
                v-model="filters"
                @close="toggleFiltersPopup(false)"
                @apply="filtersApply"
            ></history-filters-popup>
        </div>

        <base-table
            :headers="headers"
            :items="callHistory"
            :loading="loading || localLoading"
            :no-items-text="noItemsText"
            expandable-rows
            style="min-height: 0"
        >
            <template v-slot:row="{item, toggleRow}">
                <call-history-table-row
                    @expand="onExpand(item.id, $event, toggleRow)"
                    :expanded-tab="expanded.hasOwnProperty(item.id) ? expanded[item.id] : ''"
                    :key="item.id"
                    :item="item"
                ></call-history-table-row>
            </template>

            <template v-slot:expandedRow="{item, toggleRow}">
                <call-history-table-expanded-row
                    @expand="onExpand(item.id, $event, toggleRow)"
                    :item="item"
                    :expanded-tab="expanded.hasOwnProperty(item.id) ? expanded[item.id] : ''"
                ></call-history-table-expanded-row>
            </template>
        </base-table>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import HistoryFiltersPopup from "../../defaults/popups/HistoryFiltersPopup.vue";
import HistoryFilters from "../../../helpers/HistoryFilters";
import Chip from "../../defaults/Chip.vue";
import BaseInput from "../../defaults/BaseInput.vue";
import CallHistoryTableRow from "./CallHistoryTableRow.vue";
import BaseSpinner from "../../defaults/BaseSpinner.vue";
import CallHistoryPagination from "./CallHistoryPagination.vue";
import BaseTable from "../../defaults/baseTable/BaseTable.vue";
import BaseButton from "../../defaults/BaseButton.vue";
import CallHistoryTableExpandedRow from "./CallHistoryTableExpandedRow.vue";
import breakpoints from "../../../plugins/breakpoints";
import {EXPANDED_TABS} from "./config";
import Pagination from "../../../helpers/Pagination";

export default {
    name: "CallHistory",
    components: {
        CallHistoryTableExpandedRow,
        BaseButton,
        BaseTable,
        CallHistoryPagination,
        BaseInput, Chip, HistoryFiltersPopup, CallHistoryTableRow, BaseSpinner},
    props: {
        active: Boolean,
        loading: Boolean
    },
    data() {
        return {
            expanded: {},
            filtersPopup: false,
            filters: new HistoryFilters({}),
            pagination: new Pagination({page: 1, limit: 15, total: 0}),
            searchTimeoutId: null,
            localLoading: false,
            breakpoints
        }
     },
    watch: {
        callHistoryTotalCountRows(value) {
            this.pagination.total = value
        }
    },
    created() {
        document.addEventListener('sessionTerminated', this.onSessionTerminated)
    },
    destroyed() {
        document.removeEventListener('sessionTerminated', this.onSessionTerminated)
    },
    computed: {
        ...mapGetters('details', ['callHistory', 'callHistoryTotalCountRows']),

        headers() {
            if (breakpoints.isTabletOrMobile) {
                return [
                    { title: '', align: 'left', key: 'title', fr: 1.5 },
                    { title: this.$t('panel.call-history.header-type'), align: 'left', key: 'type', fr: 0.5 },
                    { title: this.$t('panel.call-history.header-date'), align: 'left', key: 'date', fr: 1 }
                ]
            }

            return [
                { title: '', align: 'left', key: 'title', fr: breakpoints.isLaptop ? 1 : 2 },
                { title: this.$t('panel.call-history.header-type'), align: 'left', key: 'type', fr: 1 },
                { title: this.$t('panel.call-history.header-line-used'), align: 'left', key: 'callerid', fr: 1 },
                { title: this.$t('panel.call-history.header-duration'), align: 'left', key: 'duration', fr: 0.5 },
                { title: this.$t('panel.call-history.header-date'), align: 'left', key: 'date', fr: 1 },
                { title: '', align: 'right', key: 'controls', fr: 1 }
            ]
        },

        noItemsText() {
            if (this.filters.count) {
                return "panel.call-history.no-results-found"
            }

            return "panel.call-history.no-calls-message"
        }
    },
    methods: {
        ...mapActions('details', ['loadCallHistory']),

        onSessionTerminated() {
            this.updateHistory(false, false)
        },

        onExpand(itemId, tabType, toggleFunction) {
            if (![EXPANDED_TABS.CALL_RECORDINGS, EXPANDED_TABS.INTEGRATIONS, EXPANDED_TABS.MOBILE].includes(tabType)) {
                return
            }

            // show tab
            if (!this.expanded.hasOwnProperty(itemId)) {
                this.$set(this.expanded, itemId, tabType)
                toggleFunction(itemId)
                return
            }

            // hide tab
            if (this.expanded[itemId] === tabType) {
                this.$delete(this.expanded, itemId)
                toggleFunction(itemId)
                return
            }

            // switch tab
            this.$set(this.expanded, itemId, tabType)
        },

        toggleFiltersPopup(show) {
            this.filtersPopup = show
        },

        filtersApply() {
            this.updateHistory(true)
            this.toggleFiltersPopup(false)
        },

        removeFilter(name) {
            this.filters.removeFilter(name)
            this.updateHistory(true)
        },

        activateSearch() {
            if (this.filters.search) {
                clearTimeout(this.searchTimeoutId)
                this.searchTimeoutId = setTimeout(() => {
                    this.updateHistory(true)
                }, 500)
            } else {
                this.updateHistory(true)
            }
        },

        updateHistory(backToFirstPage = false, showLoading = true) {
            if (backToFirstPage) {
                this.pagination.toPage(1)
            }

            this.localLoading = showLoading
            this.loadCallHistory({filters: this.filters.requestParams, pagination: this.pagination.requestParams})
                .finally(() => this.localLoading = false)
        }
    }
}
</script>

<style scoped>

</style>