<template>
    <softphone-state-default>
        <template v-slot:top>
            <person-collapsed
                v-if="isKeypadShow"
                :session="getPrimarySession"
            ></person-collapsed>

            <call-status-label v-else>{{ getPrimarySession.time }}</call-status-label>
        </template>

        <template v-slot:center>
            <!-- Keypad for transfer/parking -->
            <active-call-keypad
                v-if="showTransferKeypad"
                v-model="transferNumberModel"
                @update:current-tab-name="onUpdateTransferCurrentTabName"
                :current-tab-name="transferCurrentTabName"
                :tabs="transferTabs"
            ></active-call-keypad>

            <!-- Keypad for add call -->
            <active-call-keypad
                v-else-if="showAddCallKeypad"
                v-model="addCallNumberModel"
                :current-tab-name="addCallCurrentTabName"
                :tabs="addCallTabs"
            ></active-call-keypad>

            <!-- Keypad for sending DTMF -->
            <dtmf-dial-pad
                v-else-if="showDtmfKeypad"
                v-model="dtmfModel"
                @typedSymbol="sendDTMF"
            ></dtmf-dial-pad>

           <!-- Person and call settings buttons like mute, hold, record, etc. -->
            <template v-else>
                <person :session="getPrimarySession"></person>

                <container-call-settings-button>
                    <!-- First row -->
                    <template v-slot:first-row>
                        <call-settings-mute-button
                            :active="getIsMuted"
                            @click="mute"
                        ></call-settings-mute-button>

                        <call-settings-hold-button
                            :active="getPrimarySession.isOnHold"
                            @click="hold"
                        ></call-settings-hold-button>

                        <call-settings-add-call-button
                            @click="toggleAddCallKeypad"
                        ></call-settings-add-call-button>
                    </template>
                    <!-- First row end -->

                    <!-- Second row -->
                    <template v-slot:second-row>
                        <call-settings-keypad-button
                            @click="toggleDtmfKeypad"
                        ></call-settings-keypad-button>

                        <call-settings-record-button
                            :active="getCallIsRecording"
                            :disabled="!getIsCallRecordingAllowed || getPrimarySession.isOnHold"
                            @click="record"
                        ></call-settings-record-button>

                        <call-settings-transfer-button
                            @click="toggleTransferKeypad"
                        ></call-settings-transfer-button>
                    </template>
                    <!-- Second row end -->
                </container-call-settings-button>
            </template>
        </template>

        <template v-slot:call_control_button_col-1>
            <call-controls-call-button
                v-if="showTransferKeypad && transferCurrentTabName !== TABS.CALL_PARKING_TAB.name"
                @click="callFirstHandler"
                :disabled="!transferNumberModel || isCurrentNumSameAsTransferNum"
                :label="$t('buttons-group.buttons-group-transfer-keypad.call-first')"
            ></call-controls-call-button>
        </template>

        <template v-slot:call_control_button_col-2>
            <!-- If transfer keypad -->
            <template v-if="showTransferKeypad">
                <call-controls-transfer-button
                    v-if="transferCurrentTabName !== TABS.CALL_PARKING_TAB.name"
                    @click="transferNowHandler"
                    :disabled="!transferNumberModel || isCurrentNumSameAsTransferNum"
                    :label="$t('buttons-group.buttons-group-transfer-keypad.transfer-now')"
                ></call-controls-transfer-button>

                <call-controls-park-call-button
                    v-if="transferCurrentTabName === TABS.CALL_PARKING_TAB.name"
                    @click="transferNowHandler"
                    :disabled="!transferNumberModel || isCurrentNumSameAsTransferNum"
                    label="Park call"
                ></call-controls-park-call-button>
            </template>

            <!-- If add call keypad -->
            <call-controls-call-button
                v-else-if="showAddCallKeypad"
                @click="addToCallHandler"
                :disabled="!addCallNumberModel || isCurrentNumSameAsAddToCallNum"
            ></call-controls-call-button>

            <!-- Default -->
            <call-controls-cancel-button
                v-else
                @click="hangUp(getPrimarySession.id)"
            ></call-controls-cancel-button>
        </template>

        <template v-slot:call_control_button_col-3>
            <call-settings-back-button
                v-if="isKeypadShow"
                @click="onBackButtonClick"
            ></call-settings-back-button>
        </template>
    </softphone-state-default>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PersonCollapsed from "../../person/PersonCollapsed.vue";
import ActiveCallKeypad from "../../activeCallKeypad/ActiveCallKeypad.vue";
import {numbersAreMatched} from "../../../../../utils/phoneNumbers";
import {TABS, getTabNameByUserDefaultMode} from "../../activeCallKeypad/activeCallKeypadTabs";
import Person from "../../person/Person.vue";
import SoftphoneStateDefault from "../SoftphoneStateDefault.vue";
import CallStatusLabel from "../../CallStatusLabel.vue";
import ContainerCallSettingsButton from "../../container/ContainerCallSettingsButton.vue";
import CallSettingsMuteButton from "../../softphoneButtons/callSettings/CallSettingsMuteButton.vue";
import CallSettingsHoldButton from "../../softphoneButtons/callSettings/CallSettingsHoldButton.vue";
import CallSettingsAddCallButton from "../../softphoneButtons/callSettings/CallSettingsAddCallButton.vue";
import CallSettingsKeypadButton from "../../softphoneButtons/callSettings/CallSettingsKeypadButton.vue";
import CallSettingsRecordButton from "../../softphoneButtons/callSettings/CallSettingsRecordButton.vue";
import CallSettingsTransferButton from "../../softphoneButtons/callSettings/CallSettingsTransferButton.vue";
import CallControlsCancelButton from "../../softphoneButtons/callControls/CallControlsCancelButton.vue";
import CallControlsCallButton from "../../softphoneButtons/callControls/CallControlsCallButton.vue";
import CallControlsTransferButton from "../../softphoneButtons/callControls/CallControlsTransferButton.vue";
import CallControlsParkCallButton from "../../softphoneButtons/callControls/CallControlsParkCallButton.vue";
import CallSettingsBackButton from "../../softphoneButtons/callSettings/CallSettingsBackButton.vue";
import dtmfMixin from "../../../../../mixins/softphone/dtmfMixin";
import DtmfDialPad from "../../DtmfDialPad.vue";

export default {
    name: "SoftphoneStateActive",
    mixins: [dtmfMixin],
    components: {
        DtmfDialPad,
        CallSettingsBackButton,
        CallControlsParkCallButton,
        CallControlsTransferButton,
        CallControlsCallButton,
        CallControlsCancelButton,
        CallSettingsTransferButton,
        CallSettingsRecordButton,
        CallSettingsKeypadButton,
        CallSettingsAddCallButton,
        CallSettingsHoldButton,
        CallSettingsMuteButton,
        ContainerCallSettingsButton,
        CallStatusLabel,
        SoftphoneStateDefault,
        Person,
        ActiveCallKeypad,
        PersonCollapsed
    },
    data () {
        return {
            showTransferKeypad: false,
            showAddCallKeypad: false,
            transferNumberModel: '',
            addCallNumberModel: '',
            addCallCurrentTabName: TABS.KEYPAD_TAB.name,
            transferCurrentTabName: TABS.KEYPAD_TAB.name,
            TABS
        }
    },
    mounted() {
        document.addEventListener('speedDial', this.speedDialHandler)
    },
    destroyed() {
        document.removeEventListener('speedDial', this.speedDialHandler)
    },
    computed: {
        ...mapGetters('softphone', [
            'getIsMuted',
            'getPrimarySession',
            'isInCall',
            'getCallIsRecording',
            'getIsCallRecordingAllowed'
        ]),
        ...mapGetters('user', ['defaultAddCallMode', 'defaultTransferMode']),

        addCallTabs() {
            return [TABS.KEYPAD_TAB, TABS.CONTACTS_TAB, TABS.HISTORY_TAB]
        },

        transferTabs() {
            return [TABS.KEYPAD_TAB, TABS.CONTACTS_TAB, TABS.HISTORY_TAB, TABS.CALL_PARKING_TAB]
        },

        isCurrentNumSameAsTransferNum() {
            return numbersAreMatched(this.getPrimarySession.number, this.transferNumberModel)
        },

        isCurrentNumSameAsAddToCallNum() {
            return numbersAreMatched(this.getPrimarySession.number, this.addCallNumberModel)
        },

        isKeypadShow() {
            return this.showTransferKeypad || this.showAddCallKeypad || this.showDtmfKeypad
        }
    },
    methods: {
        ...mapActions('softphone', [
            'mute',
            'hold',
            'hangUp',
            'blindTransferCall',
            'prepareAttendedTransferCall',
            'addToCall',
            'record'
        ]),

        toggleTransferKeypad() {
            this.transferCurrentTabName = getTabNameByUserDefaultMode(this.defaultTransferMode)

            this.showTransferKeypad = !this.showTransferKeypad;
        },

        toggleAddCallKeypad() {
            this.addCallCurrentTabName = getTabNameByUserDefaultMode(this.defaultAddCallMode)

            this.showAddCallKeypad = !this.showAddCallKeypad;
        },

        onBackButtonClick() {
            if (this.showTransferKeypad) {
                this.toggleTransferKeypad()
                return
            }

            if (this.showAddCallKeypad) {
                this.toggleAddCallKeypad()
                return
            }

            this.toggleDtmfKeypad()
        },

        transferNowHandler() {
            this.blindTransferCall(this.transferNumberModel);
            this.toggleTransferKeypad();
        },

        callFirstHandler() {
            this.prepareAttendedTransferCall(this.transferNumberModel);
            this.toggleTransferKeypad();
        },

        addToCallHandler() {
            this.addToCall(this.addCallNumberModel);
            this.toggleAddCallKeypad();
        },

        speedDialHandler(event) {
            const number = event.detail.number
            if (this.showTransferKeypad) {
                this.transferNumberModel = number
            }

            if (this.showAddCallKeypad) {
                this.addCallNumberModel = number
            }
        },

        onUpdateTransferCurrentTabName(tabName) {
            this.transferCurrentTabName = tabName
        }
    }
}
</script>

<style scoped>

</style>