import Vue from 'vue';
import Vuex from "vuex";
import softphone from './modules/softphone.js';
import details from "./modules/details.js";
import queues from "./modules/queues.js";
import user from "./modules/user.js";
import integrations from "./modules/integrations.js";
import callRecordings from "./modules/callRecordings.js";
import voicemails from "./modules/voicemails.js";
import SMSes from "./modules/SMSes";
import callParkings from "./modules/callParkings";
import apps from "./modules/apps";
import twoFactorAuth from "./modules/twoFactorAuth";

Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        softphone,
        details,
        queues,
        user,
        integrations,
        callRecordings,
        SMSes,
        voicemails,
        callParkings,
        apps,
        twoFactorAuth
    },
    state: {
        alertMessages: [],
        captchaKey: '',
        activeTab: '',
        languages: [
            {value: 'en', name: 'English', icon: 'country-flag-gb'},
            {value: 'fr', name: 'French', icon: 'country-flag-fr'},
            {value: 'de', name: 'German', icon: 'country-flag-de'},
            {value: 'it', name: 'Italian', icon: 'country-flag-it'},
            {value: 'pt', name: 'Portuguese', icon: 'country-flag-pt'},
            {value: 'es', name: 'Spanish', icon: 'country-flag-es'}
        ],
        logoUrl: '',
        faviconLogoUrl: '',
        electronAppVersion: '',
        electronAppUpdateAvailable: false,
        partnerId: null,
        isLoading: true,
        appActive: true,
        appNoLicense: false,
        isUniversalWebphone: false,
        isMobileLinksAvailable: false
    },
    getters: {
        alertMessages: (state) => {
            return state.alertMessages
        },
        captchaKey: (state) => {
            return state.captchaKey
        },
        activeTab: (state) => {
            return state.activeTab
        },
        languages: (state) => {
            return state.languages
        },
        logoUrl: (state) => {
            return state.logoUrl
        },
        faviconLogoUrl: (state) => {
            return state.faviconLogoUrl
        },
        partnerId: (state) => {
            return state.partnerId
        },
        isLoading: (state) => {
            return state.isLoading
        },
        appActive: (state) => {
            return state.appActive
        },
        appNoLicense: (state) => {
            return state.appNoLicense
        },
        electronAppVersion: (state) => {
            return state.electronAppVersion
        },
        electronAppUpdateAvailable: (state) => {
            return state.electronAppUpdateAvailable
        },
        isUniversalWebphone: (state) => {
            return state.isUniversalWebphone
        },
        isMobileLinksAvailable: (state) => {
            return state.isMobileLinksAvailable
        }
    },
    mutations: {
        addAlertMessage: (state, message) => {
            state.alertMessages.push(message)
        },
        deleteAlertMessage: (state, index) => {
            state.alertMessages.splice(index, 1);
        },
        setCaptchaKey: (state, captchaKey) => {
            state.captchaKey = captchaKey;
        },
        setLogoUrl: (state, url) => {
            state.logoUrl = url;
        },
        setFaviconLogoUrl: (state, url) => {
            state.faviconLogoUrl = url;
        },
        setPartnerId: (state, partnerId) => {
            state.partnerId = partnerId;
        },
        setActiveTab: (state, tab) => {
            state.activeTab = tab;
        },
        setIsLoading: (state, loading) => {
            state.isLoading = loading;
        },
        setAppActive: (state, status) => {
            state.appActive = status;
        },
        setAppNoLicense: (state, status) => {
            state.appNoLicense = status;
        },
        setElectronAppVersion: (state, version) => {
            state.electronAppVersion = version
        },
        setElectronAppUpdateAvailable: (state, status) => {
            state.electronAppUpdateAvailable = status
        },
        setIsUniversalWebphone: (state, status) => {
            state.isUniversalWebphone = status
        },
        setIsMobileLinksAvailable: (state, status) => {
            state.isMobileLinksAvailable = status
        }
    },
    actions: {
        createAlertMessage: (context, message) => {
            context.commit('addAlertMessage', message)
        },
        removeAlertMessage: (context, {index, autoRemove}) => {
            const alert = context.getters.alertMessages[index];
            if (alert && (!alert.notHide || !autoRemove)) {
                context.commit('deleteAlertMessage', index);
            }
        },
        updateCaptchaKey: (context, captchaKey) => {
            context.commit('setCaptchaKey', captchaKey);
        },
        updateLogoUrl: (context, logoUrl) => {
            context.commit('setLogoUrl', logoUrl);
        },
        updateFaviconLogoUrl: (context, faviconLogoUrl) => {
            context.commit('setFaviconLogoUrl', faviconLogoUrl);
        },
        updatePartnerId: (context, partnerId) => {
            context.commit('setPartnerId', partnerId);
        },
        changeActiveTab: (context, tab) => {
            context.commit('setActiveTab', tab);
        },
        changeIsLoading: (context, loading) => {
            context.commit('setIsLoading', loading)
        },
        updateAppActive: (context, status) => {
            context.commit('setAppActive', status);
        },
        updateAppNoLicense: (context, status) => {
            context.commit('setAppNoLicense', status);
        },
        updateElectronAppVersion: (context, version) => {
            context.commit('setElectronAppVersion', version)
        },
        updateElectronAppUpdateAvailable: (context, status) => {
            context.commit('setElectronAppUpdateAvailable', status)
        },
        updateIsUniversalWebphone: (context, status) => {
            context.commit('setIsUniversalWebphone', status)
        },
        updateIsMobileLinksAvailable: (context, status) => {
            context.commit('setIsMobileLinksAvailable', status)
        }
    }
});