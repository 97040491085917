<template>
    <div class="history-speed-dial-list-item">
        <contact
            :number="item.number"
            color="dark_color"
        >
            <template v-slot:avatar>
                <history-avatar :call-type="item.type" ></history-avatar>
            </template>
        </contact>

        <span class="history-speed-dial-list-item-date">{{ date }}</span>
    </div>
</template>

<script>
import Contact from "../../../../../defaults/contact/Contact.vue";
import HistoryAvatar from "./HistoryAvatar.vue";
import {mapGetters} from "vuex";
import dayjsOverride from "../../../../../../helpers/dayjsOverride";

export default {
    name: "HistorySpeedDialListItem",
    components: {HistoryAvatar, Contact},
    props: {
        item: {
            type: Object
        }
    },
    computed: {
        ...mapGetters("user", ["languageCode"]),

        date() {
            return dayjsOverride(this.item.date).locale(this.languageCode).calendar(null, {
                sameDay: `[${dayjsOverride(this.item.date).format("LT")}]`,
                lastDay: `[${this.$t("panel.call-history.yesterday-call")}]`,
                lastWeek: 'dddd',
                sameElse: `[${dayjsOverride(this.item.date).format("L")}]`
            })
        }
    }
}
</script>

<style scoped>

</style>