<template>
    <div class="phonebooks-table-expanded-row">
        <integrations-inline
            :allow-call-logging="false"
            :number="number"
        ></integrations-inline>
    </div>
</template>

<script>
import IntegrationsInline from "../../integrations/IntegrationsInline.vue";

export default {
    name: "PhonebooksTableExpandedRow",
    components: {IntegrationsInline},
    props: {
        number: {
            type: String
        }
    }
}
</script>

<style scoped>

</style>