import axios from 'axios'
import store from '../store'
import router from '../router'
import i18n from "../locales";
import {sendToElectron} from "./electron";
import {authLogOut} from "./auth";

const SMS_API_ERRORS = Object.freeze({
    "customer-error-not-enough-money": {
        headerText: i18n.t('api-messages.not-enough-funds.title'),
        message: i18n.t('api-messages.not-enough-funds.message')
    },
    "error-destination-not-supported": {
        headerText: i18n.t('api-messages.destination-is-not-supported.title'),
        message: i18n.t('api-messages.destination-is-not-supported.message')
    }
})

var pathname = new URL(location.href).pathname;

var api = axios.create({
    baseURL: pathname + 'api/',
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})

let handleNextNotAuthRequest = true;

var errorHandler = (error) => {
    if (error.response.status === 401 && !error.response.config.url.includes("2fa/")) {
        sendToElectron("clearCookie")
        if (!handleNextNotAuthRequest) {
            return;
        }
        if (router.currentRoute.meta.requiresAuth && store.getters['user/isLoggedIn']) {
            store.dispatch('createAlertMessage', {
                type: 'warning',
                headerText: i18n.t('alert-messages.session-timed-out.title'),
                message: i18n.t('alert-messages.session-timed-out.message'),
                buttonText: i18n.t('alert-messages.session-timed-out.button'),
                notHide: true,
                closeHandler: () => {
                    handleNextNotAuthRequest = true
                    authLogOut()
                }
            })
            handleNextNotAuthRequest = false
        } else {
            router.currentRoute.meta.requiresAuth ? router.push('/login') : '';
        }
    }

    if ([400, 402, 404, 406].includes(error.response.status)) {
        if (error.response.data.code?.length && (error.response.data.code in SMS_API_ERRORS)) {
            const smsError = SMS_API_ERRORS[error.response.data.code]
            store.dispatch('createAlertMessage', {
                headerText: smsError.headerText,
                message: smsError.message,
                type: 'warning'
            })
            return Promise.reject(error)
        }

        let message = ""
        if (error.response.data.code?.length) {
            message += i18n.t('alert-messages.error-occure-with-code') + error.response.data.code + " \n"
        }

        message += i18n.te('api-messages.' + error.response.data.error_message) ? i18n.t('api-messages.' + error.response.data.error_message) : error.response.data.error_message

        store.dispatch('createAlertMessage', {message: message, type: 'warning'})
    }

    if (error.response.status === 500) {
        store.dispatch('createAlertMessage', {message: i18n.t('alert-messages.something-went-wrong'), type: 'error'})
    }
    return Promise.reject(error);
}

api.interceptors.response.use(
    response => response,
    error => errorHandler(error)
)

export default () => {
    return api
}
