<template>
    <div class="portal-wrapper">
        <TopBar :windowWidth="windowWidth"></TopBar>
        <div class="content">
            <Softphone
                v-show="(windowWidth > 800 || activeTab == 'softphone')"
                :settings-is-loading="workspaceIsLoading"
                :softphone-settings="softphoneSettings"
            ></Softphone>
            <Panel  v-show="computedShow" :windowWidth="windowWidth" :phonebooks-is-loading="phonebooksIsLoading" :history-is-loading="historyIsLoading"></Panel>
        </div>
        <ContactPopup v-if="contactPopup" :contact="contact" @close="contactPopup = false"></ContactPopup>
        <DeleteContactPopup v-if="deleteContactPopup" :contact="contact" @close="deleteContactPopup = false"></DeleteContactPopup>
    </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import Softphone from "./softphone/Softphone.vue";
import TopBar from "./topBar/TopBar.vue";
import Panel from "./panel/Panel.vue";
import ContactPopup from "./defaults/popups/ContactPopup.vue";
import DeleteContactPopup from "./defaults/popups/DeleteContactPopup.vue";
import SocketIOHelper from "../helpers/SocketIOHelper";
import {isElectronExist, sendToElectron} from "../utils/electron";
import {changeLocale} from "../locales";
import HistoryFilters from "../helpers/HistoryFilters";
import {ZAPIER_TYPE} from "../helpers/integrations/crms";
import Api from "../utils/api.js"

export default {
    name: "Home",
    components: {ContactPopup, DeleteContactPopup, TopBar, Softphone, Panel},
    data() {
        return {
            contactPopup: false,
            deleteContactPopup: false,
            contact: {},
            windowWidth: 1200,
            workspaceIsLoading: true,
            phonebooksIsLoading: true,
            historyIsLoading: true,
            softphoneSettings: {}
        }
    },
    computed: {
        ...mapGetters(['alertMessages', 'activeTab', 'faviconLogoUrl']),
        ...mapGetters('user', [
            'user',
            'isLoggedIn',
            'languageCode',
            'pbxUrl',
            'isOnline',
            'isIntegrationsEnabled',
            'realtimeToken',
            'defaultPage',
            'isUnreadVoicemailMessagesExists'
        ]),
        ...mapGetters('softphone', ['getSipLogin']),
        ...mapGetters('details', ['phonebooks', 'extensions', 'getContactsByNumber']),
        ...mapGetters('integrations', ['integrations', 'zapierEnabled']),

      computedShow () {
          return this.windowWidth > 800 || this.activeTab != 'softphone';
      }
    },
    methods: {
      ...mapActions('user', ['setUser',"updateIsUnreadVoicemailMessagesExists", 'updateIsIntegrationsEnabled']),
      ...mapActions('softphone', ['ctxSipStop']) ,
      ...mapActions('details', ['loadCallHistory', 'loadPhonebooks']),
      ...mapActions('queues', ['getQueues']),
      ...mapActions(['createAlertMessage', 'changeActiveTab', 'updateAppActive']),
      ...mapActions('integrations', ['getIntegrations', 'getZapierSubscriptions', 'removeIntegration', 'resetCallLogBufferForCrm', 'resetCallLogBuffer']),
      ...mapActions('callParkings', ['getCallParkings']),
      ...mapActions('twoFactorAuth', ['getTwoFactorAuthStatus']),

      showContactPopup (e) {
        this.contact = Object.assign({}, e.detail.contact);
        this.contactPopup = true;
      },
      showDeleteContactPopup (e) {
        this.contact = Object.assign({}, e.detail.contact);
        this.deleteContactPopup = true;
      },
      handleResize() {
        if ( window.innerWidth > 800 && this.activeTab == 'softphone') {
          this.changeActiveTab('phonebooks');
        }

        this.windowWidth = window.innerWidth;
      }
    },
    watch: {
        isUnreadVoicemailMessagesExists(value) {
            sendToElectron("missedVoicemailMessages", Boolean(value))
        }
    },
    created () {
        SocketIOHelper.socket.on("update_is_unread_voicemail_messages_exists", (data) => {
            this.updateIsUnreadVoicemailMessagesExists(data.is_unread_voicemail_messages_exists)
        })
        SocketIOHelper.socket.on('multiple_sessions', () => {
            console.log('multiple_sessions')

            if (!isElectronExist()) {
                this.updateAppActive(false)
                this.ctxSipStop()
                SocketIOHelper.disconnect()
            }
        })
        SocketIOHelper.socket.on("webphone_disable_integration", (data) => {
            data.integrations.forEach((type) => {
                this.removeIntegration(type)
                this.resetCallLogBufferForCrm(type)
            })
        })

        SocketIOHelper.socket.on("webphone_disable_all_integrations", (data) => {
            this.integrations.forEach((integration) => {
                this.removeIntegration(integration.type)
            })

            this.updateIsIntegrationsEnabled(0)
            this.resetCallLogBuffer({})
        })
        Api().get("/workspace_load")
            .then((response) => {
                this.setUser(response.data)

                if (this.defaultPage !== 'dialpad') {
                    this.changeActiveTab(this.defaultPage);
                } else if ( window.innerWidth <= 800) {
                    this.changeActiveTab('softphone');
                } else {
                    this.changeActiveTab('phonebooks');
                }

                this.softphoneSettings = response.data.softphone_settings

                sendToElectron("setDNDStatus", !Boolean(this.user.isOnline))

                changeLocale(this.languageCode)

                Promise.all([
                    Promise.resolve(this.loadPhonebooks()),
                    Promise.resolve(this.getCallParkings())
                ])
                .then(() => {
                    this.phonebooksIsLoading = false

                    return this.loadCallHistory({pagination: {page: 1, items_per_page: 15}})
                })
                .then(() => {
                    this.historyIsLoading = false
                })
                .catch(() => {})

                this.getTwoFactorAuthStatus().catch(() => {})

                SocketIOHelper.connect(this.pbxUrl, this.realtimeToken)

                if (this.isIntegrationsEnabled) {
                    this.getIntegrations({tenant: this.user.crmTenantName, email: this.user.email}).then(() => {
                        if (this.zapierEnabled) {
                            this.getZapierSubscriptions({tenant: this.user.crmTenantName, email: this.user.email}).catch(() => {})
                        }
                    }).catch(() => {})
                }

                sendToElectron("missedVoicemailMessages", Boolean(this.isUnreadVoicemailMessagesExists))
            })
            .catch(() => {})
            .finally(() => {
                this.workspaceIsLoading = false
            })
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
    },
    mounted: function () {
        document.addEventListener('showContactPopup', this.showContactPopup)
        document.addEventListener('showDeleteContactPopup', this.showDeleteContactPopup)
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
        document.removeEventListener('showContactPopup', this.showContactPopup)
        document.removeEventListener('showDeleteContactPopup', this.showDeleteContactPopup)
        SocketIOHelper.socket.removeAllListeners("webphone_disable_integration")
        SocketIOHelper.socket.removeAllListeners("webphone_disable_all_integrations")
        SocketIOHelper.socket.removeAllListeners("multiple_sessions")
        SocketIOHelper.socket.removeAllListeners("update_is_unread_voicemail_messages_exists")
    },
}

</script>
