<template>
    <div class="phonebooks-actions-cell" style="display: flex; flex-direction: row; column-gap: 12px; justify-content: flex-end">
        <base-button
            v-if="canShowIntegrations"
            @click="onClickIntegrations"
            :size="'medium'"
            :color="isExpanded ? 'dark-gray' : 'table'"
            :icon="'icon-join-right-black'"
        ></base-button>

        <base-button
            v-if="!isReadOnlyPhonebook"
            @click="showEditContactPopup(item)"
            :size="'medium'"
            :color="'table'"
            :icon="'icon-edit'"
        ></base-button>

        <base-button
            v-if="!isReadOnlyPhonebook"
            @click="showDeleteContactPopup(item)"
            :size="'medium'"
            :color="'table'"
            icon="icon-delete-forever"
            class="delete-contact-btn"
        ></base-button>
    </div>
</template>

<script>
import BaseButton from "../../../defaults/BaseButton.vue";
import {showEditContactPopup, showDeleteContactPopup} from "../../../../utils/phonebooks";
import {canShowIntegrations} from "../../../../utils/integrations";
import {mapGetters} from "vuex";

export default {
    name: "PhonebooksActionsCell",
    components: {BaseButton},
    props: {
        item: {
            type: Object
        },
        isExpanded: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters("details", ["getIsReadOnlyPhonebook"]),

        isReadOnlyPhonebook() {
            return this.getIsReadOnlyPhonebook(this.item.phonebook_id)
        },

        canShowIntegrations() {
            return canShowIntegrations(this.item.number)
        }
    },
    methods: {
        onClickIntegrations() {
            this.$emit("click-integrations")
        },

        showEditContactPopup,
        showDeleteContactPopup
    }
}
</script>

<style scoped>

</style>