<template>
    <table>
        <colgroup>
            <col
                v-for="(widthColumn, idx) in widthColumns"
                :width="widthColumn"
                :key="idx"
            >
        </colgroup>

        <thead class="base-table-header">
            <tr>
                <base-table-header-item
                    v-if="selectable"
                    :header="new TableHeader({title: '', key: '', align: 'left'})"
                >
                    <base-checkbox
                        @change="onSelect"
                        :value="selected"
                        :disabled="!multiple"
                        :semi-selected="semiSelected"
                    ></base-checkbox>
                </base-table-header-item>

                <base-table-header-item
                    v-for="(header, idx) in headers"
                    @update:sort="onUpdateSort(idx, $event)"
                    :key="idx"
                    :header="header"
                ></base-table-header-item>
            </tr>
        </thead>
    </table>
</template>

<script>
import BaseCheckbox from "../BaseCheckbox.vue";
import TableHeader from "../../../models/baseTable/TableHeader";
import BaseTableHeaderItem from "./BaseTableHeaderItem.vue";
import {TABLE_HEADER_SORT} from "../../../models/baseTable/TableHeader";

export default {
    name: "BaseTableHeader",
    components: {BaseTableHeaderItem, BaseCheckbox},
    props: {
        headers: {
            type: Array,
            default: [],
            validator(value) {
                return value.every((header) => header instanceof TableHeader)
            }
        },
        widthColumns: {
            type: Array,
            default: []
        },
        selectable: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false
        },
        semiSelected: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            TableHeader
        }
    },
    methods: {
        onSelect() {
            this.$emit("select")
        },

        onUpdateSort(headerIdx, newSortDirection) {
            const newHeaders = this.headers.map((header) => {
                const clone = Object.assign(Object.create(Object.getPrototypeOf(header)), header)
                clone.sort = TABLE_HEADER_SORT.DEFAULT
                return clone
            })

            newHeaders[headerIdx].sort = newSortDirection

            this.$emit("update:sort", newHeaders)
        }
    }
}
</script>

<style scoped>

</style>