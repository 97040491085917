<template>
    <div class="call-history-mobile-expanded-tab-row">
        <div class="call-history-mobile-expanded-tab-row-title">{{ title }}</div>

        <slot name="value">
            <div class="call-history-mobile-expanded-tab-row-value">{{ value }}</div>
        </slot>
    </div>
</template>

<script>
export default {
    name: "MobileExpandedTabRow",
    props: {
        title: {
            type: String
        },
        value: {
            type: String,
            default: ""
        }
    }
}
</script>

<style scoped>

</style>